import Mock from 'mockjs'

const data = Mock.mock({
  'items|5': [{
    id: '@id',
    bu: '@string(4, 6)',
    style: '@string(4, 6)',
    platform: '@string(4, 6)',
    site: '@cname',
    display_time: '@datetime',
    pjm: '@cname'
  }]
})

export default [
  {
    url: '/base/approvalNoticeConfig/alList',
    type: 'get',
    response: () => {
      const items = data.items
      return {
        code: 0,
        msg: '操作成功',
        datas: {
          total: items.length,
          items: items
        }
      }
    }
  }
]
