<template>
  <div
    :id="uid"
    class="j-hover-btn"
    :style="getStyle()"
    @mousedown="itemClick"
  >
    <!-- {{ text }} -->
    <img src="../../assets/img/robort.png" alt="logo" style="width:100%;height:100%">
    <div class="text">在线客服</div>
  </div>
</template>

<script>
export default {
  name: 'JHoverBtn',
  props: {
    bgColor: {
      type: String,
      default: ''
    },
    autoHide: {
      type: Boolean,
      default: true
    },
    clickDis: {
      type: Number,
      default: 10
    },
    showWidth: {
      type: Number,
      default: 15
    },
    width: {
      type: Number,
      default: 50
    },
    radius: {
      type: Boolean,
      default: true
    },
    btnStyle: {
      type: Object,
      default: () => {}
    },
    text: {
      type: String,
      default: '悬浮按钮'
    },
    zIndex: {
      type: Number,
      default: 999999
    }
  },
  data() {
    return {
      startX: '',
      startY: '',
      clickStatus: false,
      isClick: true,
      uid: ''
    }
  },
  created() {
    this.setId()
  },
  mounted() {
    this.preventEvent()
    window.addEventListener('mouseup', this.handleMouseup)
    window.addEventListener('mouseover', this.handleMouseover)
  },
  methods: {
    setId() {
      this.uid = this.getUId() + 'j-hover-btn'
    },
    getStyle(res = '') {
      res += 'background-color:' + this.bgColor + ';'
      res += 'width:' + this.width + 'px;'
      res += 'height:' + this.width + 'px;'
      res += 'line-height:' + this.width + 'px;'
      res += 'z-index:' + this.zIndex + ';'
      if (this.radius) {
        res += 'border-radius: 50% 50%;'
      }
      const btnStyle = this.btnStyle
      for (const k in btnStyle) {
        res += this.camelTo_(k) + ':' + btnStyle[k] + ';'
      }
      return res
    },
    // 阻止默认事件
    preventEvent() {
      document.getElementById(this.uid).ondragstart = function() {
        return false
      }
      document.getElementById(this.uid).onselectstart = function() {
        return false
      }
    },
    getRandomLetter(size = 8) {
      let res = ''
      for (let i = 0; i < size; i++) {
        const ind = Math.floor(Math.random() * 26)
        res += String.fromCharCode(ind + 65)
      }
      return res
    },
    getUId(letterNums = 8, numberNums = 6) {
      const uId =
        this.getRandomLetter(letterNums) +
        '-' +
        Math.ceil(Math.random() * Math.pow(10, numberNums))
      return uId
    },
    camelTo_(str) {
      let res = ''
      for (let i = 0; i < str.length; i++) {
        if (str[i] >= 'A' && str[i] <= 'Z') {
          if (i === 0) res += str[i].toLowerCase()
          else {
            res += '-' + str[i].toLowerCase()
          }
        } else {
          res += str[i]
        }
      }
      return res
    },
    windowPreventEvent() {
      window.ondragstart = function() {
        return false
      }
      window.onselectstart = function() {
        return false
      }
    },
    windowPreventEventCancel() {
      window.ondragstart = null
      window.onselectstart = null
    },
    itemClick(event) {
      this.startX = event.pageX - window.scrollX
      this.startY = event.pageY - window.scrollY
      this.clickStatus = true
      this.windowPreventEvent()
    },
    // 鼠标抬起时
    handleMouseup(event) {
      if (this.clickStatus) {
        const endX = event.pageX - window.scrollX
        // const endY = event.pageY - window.scrollY
        if (this.isClick) {
          this.$emit('hoverBtnClick')
        } else {
          if (!this.autoHide) return
          const width = document.body.offsetWidth
          // const height = document.body.offsetHeight
          const dom = document.getElementById(this.uid)
          if (endX < this.width / 2) {
            dom.style.left = -(this.width - this.showWidth) + 'px'
          } else if (endX > width - this.width / 2) {
            dom.style.left = width - this.showWidth + 'px'
          }
        }
        this.clickStatus = false
        this.isClick = true
        this.windowPreventEventCancel()
      }
    },

    handleMouseover(event) {
      if (this.clickStatus) {
        const endX = event.pageX - window.scrollX
        const endY = event.pageY - window.scrollY
        const dom = document.getElementById(this.uid)
        if (
          Math.abs(endX - this.startX) > this.clickDis ||
                    Math.abs(endY - this.startY) > this.clickDis
        ) {
          this.isClick = false
        }
        console.log('dom.style: ', dom)

        dom.style.left = endX - this.width / 2 + 'px'
        dom.style.top = endY - this.width / 2 + 'px'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.j-hover-btn {
    text-align: center;
    cursor: pointer;
    position: fixed;
    right:8px;
    bottom:100px;
    .text {
      font-size:12px;
      margin-top:-12px;
      line-height:12px;
      color:#1890ff;
      font-weight:700
    }
}
</style>
