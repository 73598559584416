const pageOutbound = {
  pager: {
    current: 1,
    size: 10,
    pages: 1,
    total: 1
  },
  records: [
    {
      id: 8,
      createById: 0,
      createByName: '',
      createTime: '2019-11-26 17:16:22',
      modifyById: 0,
      modifyByName: '',
      modifyTime: null,
      tenantId: 1,
      outboundCode: 'SO12019112601001',
      sourceCode: 'cs',
      outboundType: 'paltform',
      outboundWarehouseId: 1,
      sendSellPlatformId: 11,
      sendSiteCode: 'US',
      outboundStatus: 1,
      outboundGoodsPlatformId: 10,
      deliveryTime: '2019-11-27 12:12:12',
      remark: '自测'
    }
  ]
}

const stockout = [
  {
    url: '/mock/wms-out2b/outbound/pageOutbound',
    type: 'get',
    response: () => {
      return {
        code: 0,
        msg: '操作成功',
        datas: pageOutbound
      }
    }
  }
]

export default stockout
