import Layout from '@/layout'
import NullRouterView from '@/layout/null-router-view'
import Iframe from '@/layout/Iframe'

const routerMap = {
  Layout,
  NullRouterView,
  Iframe,
  // Turnover管理
  TurnoverMGMT: () => import('@/views/styleinfomgmt/turnovermgmt/index'),
  TurnoverMGMTAdded: () => import('@/views/styleinfomgmt/turnovermgmt/added'),
  // 采购价格
  PurchasePrice: () => import('@/views/styleinfomgmt/purchaseprice/index'),
  PurchasePriceAdded: () => import('@/views/styleinfomgmt/purchaseprice/added'),
  // 采购计划index
  PurchasPlan: () => import('@/views/purchasbusiness/purchasplan/index'),
  // 新建/修改采购计划
  AddedPlanOrders: () =>
    import('@/views/purchasbusiness/purchasplan/addedplanorder/index'),
  // 批量新增采购计划
  AllAddedOrder: () =>
    import('@/views/purchasbusiness/purchasplan/addedplanorder/alladdedorder'),
  // 生成采购计划
  GenerateOrder: () =>
    import('@/views/purchasbusiness/purchasplan/generateorder/index'),
  // 添加超买明细
  AddOverbought: () =>
    import('@/views/purchasbusiness/purchasplan/AddOverbought/index'),
  // 查看
  ViewOrder: () =>
    import('@/views/purchasbusiness/purchasplan/vieworder/index'),
  // 查看转单明细
  ViewOrderDetail: () =>
    import('@/views/purchasbusiness/purchasplan/vieworderdetail/index'),
  // 采购订单
  PurchaseOrder: () => import('@/views/purchasbusiness/purchaseorder/index'),
  // 采购订单详情
  PurchDetail: () => import('@/views/purchasbusiness/purchaseorder/purchdetail/index'),
  // 合同管理
  OrderContract: () => import('@/views/purchasbusiness/contract/ordercontract/index'),
  FrameContract: () => import('@/views/purchasbusiness/contract/framecontract/index'),
  SupplementaryContract: () => import('@/views/purchasbusiness/contract/supplementarycontract/index'),
  // 鞋标文件管理
  ShoeLabelFile: () => import('@/views/purchasbusiness/shoe-label-file'),
  // 生产进度查询
  Prodprogress: () => import('@/views/purchasbusiness/prodprogress/index'),
  // 审批流管理
  ApprovalFlowMGMT: () => import('@/views/approvalflow/approvalflowmgmt/index'),
  // 审批流管理详情
  ApprovalFlowAdded: () => import('@/views/approvalflow/approvalflowmgmt/added'),

  // 质检管理
  Qualitymgmt: () => import('@/views/quality-inspection/quality-mgmt/index'),
  // 质检管理新增
  QualitymgmtAdded: () =>
    import('@/views/quality-inspection/quality-mgmt/added'),
  // 文件导出
  ImportFile: () => import('@/views/import-file'),
  // 报表管理
  PurchaseLedger: () => import('@/views/report-manage/purchase-Ledger/index'),
  OpenOrder: () => import('@/views/report-manage/open-order/index'),
  DelayOrder: () => import('@/views/report-manage/delay-order/index'),
  GoodsToPredict: () => import('@/views/report-manage/goods-to-predict/index'),
  PayToPredict: () => import('@/views/report-manage/pay-to-predict/index'),
  FailToPredict: () => import('@/views/report-manage/fail-to-predict/index'),
  AgingReport: () => import('@/views/report-manage/aging-report/index'),
  PayableReport: () => import('@/views/report-manage/payable-report/index'),
  OpenExtension: () => import('@/views/report-manage/open-extension/index'),
  PurchasePlan: () => import('@/views/report-manage/purchase-plan/index'),
  PurchaseOrderReport: () => import('@/views/report-manage/purchase-order/index'),
  // 小工具 鞋印批次号
  ShoeBatchNumber: () => import('@/views/scm-widget/shoe-batch-number/index'),
  // 供应商管理中心
  Supplier: () => import('@/views/third-party/supplier/index'),
  SupplierAdded: () => import('@/views/third-party/supplier/added'),
  ChangeVendorName: () => import('@/views/third-party/supplier/changeVendorName'),
  SupplierDetails: () => import('@/views/third-party/supplier/details'),
  SupplierQuote: () => import('@/views/third-party/supplier-quote/index'),
  AddedQuote: () => import('@/views/third-party/supplier-quote/added-quote/index'),
  AllAddedQuote: () => import('@/views/third-party/supplier-quote/added-quote/all-added-quote/index'),
  ViewQuote: () => import('@/views/third-party/supplier-quote/view-quote/index'),
  QuotationUpdateRecord: () => import('@/views/third-party/quotation-update-record/index'),
  QuotationUpdateRecordDetail: () => import('@/views/third-party/quotation-update-record/details'),
  PoPriceAdjustment: () => import('@/views/third-party/poprice-adjustment/index'),
  PoPriceAdjustmentAdd: () => import('@/views/third-party/poprice-adjustment/add/index'),
  PoPriceAdjustmentBatchAdd: () => import('@/views/third-party/poprice-adjustment/add/batch-add/index'),
  // 财务管理
  PurchanseReconciliation: () => import('@/views/financial-manage/purchanse-reconciliation/index'),
  NoReconciliation: () => import('@/views/financial-manage/no-reconciliation/index'),
  PayManage: () => import('@/views/financial-manage/pay-manage/index'),
  DepositCheck: () => import('@/views/financial-manage/deposit-check/index'),
  PurchanseReconciliationAdded: () => import('@/views/financial-manage/purchanse-reconciliation/detail'),
  // 对账单管理
  StatementManagement: () => import('@/views/financial-manage/supplier-settlement/statement-management/index'),
  StatementManagementDetail: () => import('@/views/financial-manage/supplier-settlement/statement-management/details/index'),
  // 赔偿单详情
  StatementManagementIndemnityDetail: () => import('@/views/financial-manage/supplier-settlement/statement-management/details/indemnity'),
  PurchaseSettlement: () => import('@/views/financial-manage/supplier-settlement/purchase-settlement/index'),
  FinancialPayment: () => import('@/views/financial-manage/supplier-settlement/financial-payment/index'),
  // 发票管里
  InvoiceManage: () => import('@/views/financial-manage/supplier-settlement/invoice-manage/index'),
  InvoiceManageAdded: () => import('@/views/financial-manage/supplier-settlement/invoice-manage/added/index'),
  // 货主管理
  OwnerMannagement: () => import('@/views/third-party/owner-mannagement/index'),
  // 采购退货
  PurchaseReturn: () => import('@/views/purchasereturn/index'),
  PurchaseReturnAdded: () => import('@/views/purchasereturn/added/index'),
  PurchaseReturnDetail: () => import('@/views/purchasereturn/detail/index'),
  // 安全库存管理
  SafetyStockApplicationForm: () => import('@/views/safestock-manage/safestock-applicationForm/index'),
  SafetyStockApplicationFormDetail: () => import('@/views/safestock-manage/safestock-applicationForm/details'),
  SafetyStockApplicationFormAdded: () => import('@/views/safestock-manage/safestock-applicationForm/add'),
  SafetyStockQuiry: () => import('@/views/safestock-manage/safestock-quiry/index'),
  ExceptionHandling: () => import('@/views/safestock-manage/safestock-inventory-exception-handling/index'),
  // 稽查管理
  AuditManagement: () => import('@/views/audit-management/audit/index'),
  AuditManagementAdded: () => import('@/views/audit-management/audit/add/index'),
  AuditManagementDetail: () => import('@/views/audit-management/audit/detail/index'),
  AuditInspectionReport: () => import('@/views/audit-management/inspection-report/index'),
  AssetRules: () => import('@/views/audit-management/assets-rules/index'),
  // 配置管理
  ResponsiblePerson: () => import('@/views/configuration-manage/responsible-person/index'),
  ApprovalNotice: () => import('@/views/configuration-manage/approval-notice/index'),
  ResponsibleAutomatic: () => import('@/views/configuration-manage/responsible-automatic/index'),
  TemplateManage: () => import('@/views/configuration-manage/template-manage/index'),
  ProductManage: () => import('@/views/configuration-manage/product-manage/index'),
  AlarmtManage: () => import('@/views/configuration-manage/alarm-manage/index'),

  // 赔偿管理
  CompensationManagement: () => import('@/views/compensation-manage/vendor-compensation/index'),

  // 计划交付管理
  Logistics: () => import('@/views/plan-deliver/logistics/index'),
  RtrSsdConfiguration: () => import('@/views/plan-deliver/rtr-ssd-configuration/index'),
  RtrSsdConfigurationAdded: () => import('@/views/plan-deliver/rtr-ssd-configuration/added/index'),
  MpsDays: () => import('@/views/plan-deliver/mps-days/index'),
  // 库存调配
  RedundantInventory: () => import('@/views/plan-deliver/inventory-allocation/redundancy-inventory/index'),
  Allocation: () => import('@/views/plan-deliver/inventory-allocation/redundancy-inventory/allocation/index'),
  InventoryLog: () => import('@/views/plan-deliver/inventory-allocation/inventory-log/index'),
  ReplenishmentTimeLimit: () => import('@/views/plan-deliver/replenishment-timelimit/index'),

  Shipping: () => import('@/views/plan-deliver/DemandPlan/Shipping/index'),
  PurchasingPlan: () => import('@/views/plan-deliver/DemandPlan/PurchasingPlan/index'),
  OrderPlan: () => import('@/views/plan-deliver/DemandPlan/OrderPlan/index'),
  TurnOver: () => import('@/views/plan-deliver/TurnOver/index'),

  FlippingTime: () => import('@/views/plan-deliver/flipping-time/index'),
  SalesForecastSynchronization: () => import('@/views/plan-deliver/sales-forecast-synchronization/index'),
  // 补货翻单
  ReplenishmentRecords: () => import('@/views/plan-deliver/replenishment-reversal/replenishment-records/index'),
  FlipOrderManagement: () => import('@/views/plan-deliver/replenishment-reversal/flip-order-management/index'),
  ModifyFlipOrder: () => import('@/views/plan-deliver/replenishment-reversal/flip-order-management/modify/index'),
  // 调拨单管理
  TransferOrderManagement: () => import('@/views/plan-deliver/transfer-order-management/index'),
  TransferOrderAdded: () => import('@/views/plan-deliver/transfer-order-management/added/index'),
  AllTransferOrderAdded: () => import('@/views/plan-deliver/transfer-order-management/added/all-transfer-order-added'),
  TransferOrderModify: () => import('@/views/plan-deliver/transfer-order-management/modify/index'),
  TransferOrderView: () => import('@/views/plan-deliver/transfer-order-management/details/index'),
  ToWarehouse: () => import('@/views/plan-deliver/transfer-order-management/to-warehouse/index'),
  // 补货管理
  ReplenishManage: () => import('@/views/plan-deliver/replenishment-reversal/ReplenishManage/index'),
  // 补货确认
  ReplenishConfirmation: () => import('@/views/plan-deliver/replenishment-reversal/ReplenishManage/Confirmation/index'),
  // 预约运输管理
  AppointTransportManagement: () => import('@/views/purchasbusiness/appoint-transport/index'),

  AppointTransportAdded: () => import('@/views/purchasbusiness/appoint-transport/added/index'),
  // 包装管理
  PackagingCode: () => import('@/views/purchasbusiness/packaging-code/index'),
  PackagingCodeAdded: () => import('@/views/purchasbusiness/packaging-code/added/index'),
  PackagingManagement: () => import('@/views/purchasbusiness/packaging-management/index'),
  MoqManage: () => import('@/views/configuration-manage/moq-manage/index'),
  // 统计分析
  PurchasingStatistics: () => import('@/views/statistical-analysis/purchasing-statistics/index'),
  AllotStatistics: () => import('@/views/statistical-analysis/allot-statistics/index'),
  SalesStatistics: () => import('@/views/statistical-analysis/sales-statistics/index'),
  StockStatistics: () => import('@/views/statistical-analysis/supply-analysis/stock-statistics/index'),
  InventoryStatistics: () => import('@/views/statistical-analysis/inventory-statistics/index'),
  SalespPanAccuracy: () => import('@/views/statistical-analysis/sales-plan-statistics/sales-plan-accuracy/index'),
  TurnoverAnalysis: () => import('@/views/statistical-analysis/inventory-forcasting-statistics/turnover-analysis/index'),
  VOC: () => import('@/views/purchasbusiness/VOC')
}

export default routerMap
