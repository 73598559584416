import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'

export const JWT_TOKEN = 'jwt_token'
export const WG_OAUTH_REFRESH = 'wg_oauth_refresh'
export const WG_OAUTH = 'wg_oauth'

function getDomain() {
  const domain = document.domain || window.location.host
  if (domain.includes('wangoon.cn')) {
    return 'wangoon.cn'
  } if (domain.includes('test.wangoon.cn')) {
    return 'test.wangoon.cn'
  } if (domain.includes('uat.wangoon.cn')) {
    return 'uat.wangoon.cn'
  } else {
    return domain
  }
}

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  const domain = document.domain || window.location.host
  if (domain.includes('wangoon.cn')) {
    return Cookies.set(TokenKey, token, { domain: 'wangoon.cn' })
  } if (domain.includes('test.wangoon.cn')) {
    return Cookies.set(TokenKey, token, { domain: 'test.wangoon.cn' })
  } if (domain.includes('uat.wangoon.cn')) {
    return Cookies.set(TokenKey, token, { domain: 'uat.wangoon.cn' })
  } else {
    return Cookies.set(TokenKey, token)
  }
}

export function removeToken() {
  Cookies.remove(TokenKey)
  Cookies.remove(JWT_TOKEN, { domain: getDomain() })
  Cookies.remove(WG_OAUTH_REFRESH, { domain: getDomain() })
  return Cookies.remove(TokenKey, { domain: getDomain() })
}

export function getTokenByKey(key) {
  return Cookies.get(key)
}
export function setTokenByKey(key, token) {
  const domain = document.domain || window.location.host
  if (domain.includes('wangoon.cn')) {
    return Cookies.set(key, token, { domain: 'wangoon.cn' })
  } if (domain.includes('test.wangoon.cn')) {
    return Cookies.set(key, token, { domain: 'test.wangoon.cn' })
  } if (domain.includes('uat.wangoon.cn')) {
    return Cookies.set(key, token, { domain: 'uat.wangoon.cn' })
  } else {
    return Cookies.set(key, token)
  }
}

export function getProtocolAdjustedUrl(url) {
  const currentProtocol = window.location.protocol

  // '//' 开始，来删除原URL的协议部分
  const urlWithoutProtocol = url.replace(/^(http:|https:)?\/\//, '')

  // 返回新的URL，协议部分与当前页面的协议相同
  return `${currentProtocol}//${urlWithoutProtocol}`
}
