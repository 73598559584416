<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { handleBreadTitle } from '@/utils/monitor'

export default {
  name: 'App',

  watch: {
    $route(route) {
      window._paq.push(['trackPageView', handleBreadTitle(route)])
    }
  }
}
</script>

