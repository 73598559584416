// 上报一个事件，例如点击事件，播放事件等，在主动上报中比较常用。
export const trackEvent = (
  category,
  action,
  name,
  value
) => {
  if (window._paq) {
    window._paq.push(['trackEvent', category, action, name, value])
  } else {
    console.warn('can not found window._paq')
  }
}

// 二次封装，专门上报弹窗的动作，例如 action 参数可以填写 show, close
export const trackDialogEvent = (action, name, value) => {
  if (window._paq) {
    window._paq.push(['trackEvent', '弹窗唤起次数', action, name, value])
  } else {
    console.warn('can not found window._paq')
  }
}

// 上报错误
export const trackErrorEvent = (action, name, value) => {
  if (window._paq) {
    window._paq.push(['trackEvent', 'Error', action, name, value])
  } else {
    console.warn('can not found window._paq')
  }
}

/**
 * @description 内部搜索跟踪（列表查询）
 * @param {*} keyword 表单搜索条件
 * @param {*} category 当前页面
 * @param {*} resultsCount
 */
export const trackSiteSearch = (
  keyword,
  category,
  searchCount
) => {
  if (window._paq) {
    window._paq.push(['trackSiteSearch', keyword, `表单搜索: ${category}`, searchCount])
  } else {
    console.warn('can not found window._paq')
  }
}

import i18n from '@/lang'
export const handleBreadTitle = (route) => {
  const matched = route.matched.filter(item => item.meta?.title && item.meta.breadcrumb !== false)
  const title = []
  matched?.map((v) => {
    const item = i18n.te('route.' + v?.meta?.title) ? i18n.t('route.' + v?.meta?.title) : v?.meta?.title
    title.push(item)
  })

  return title.join('/')
}

export const trackExportEvent = (action, name, value) => {
  if (window._paq) {
    window._paq.push(['trackEvent', '导出次数', action, name, value])
  } else {
    console.warn('can not found window._paq')
  }
}

export const trackImportEvent = (action, name, value) => {
  if (window._paq) {
    window._paq.push(['trackEvent', '导入次数', action, name, value])
  } else {
    console.warn('can not found window._paq')
  }
}

export const trackPaginationEvent = (action, name, value) => {
  if (window._paq) {
    window._paq.push(['trackEvent', '分页查看', action, name, value])
  } else {
    console.warn('can not found window._paq')
  }
}

export const trackDimensionEvent = (action, name, value) => {
  if (window._paq) {
    window._paq.push(['trackEvent', '切换数据维度', action, name, value])
  } else {
    console.warn('can not found window._paq')
  }
}

// tab栏切换
export const trackTabClickEvent = (action, name, value) => {
  if (window._paq) {
    window._paq.push(['trackEvent', 'Tab栏切换', action, name, value])
  } else {
    console.warn('can not found window._paq')
  }
}

// 按钮操作次数
export const trackBtnClickEvent = (action, name, value) => {
  if (window._paq) {
    window._paq.push(['trackEvent', '按钮操作', action, name, value])
  } else {
    console.warn('can not found window._paq')
  }
}
// 文本框操作
export const trackInputBlurEvent = (action, name, value) => {
  if (window._paq) {
    window._paq.push(['trackEvent', '文本框操作', action, name, value])
  } else {
    console.warn('can not found window._paq')
  }
}
