<template>
  <div class="iframe">
    <el-skeleton v-show="loading" :rows="9" animated />
    <iframe v-show="!reload" id="iframeContent" ref="iframeContent" name="iframeContent" :class="{'iframe-show': loading}" importance="high" :src="iframeUrl" frameborder="0" :title="$route.name" @load="load" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      reload: false
    }
  },
  computed: {
    iframeUrl() {
      return this.$route.meta.iframeUrl
    }

  },
  created() {

  },
  mounted() {

  },
  methods: {
    load() {
      this.loading = false
      this.reload = false
    }
  }
}
</script>

<style>
#iframeContent {
  height:  calc(100vh - 140px);
  width: 100%;
}
.iframe-show {
  position: absolute;
  left: 999999px;
}
</style>
