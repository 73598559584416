import { cloneDeep } from 'lodash'

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
      time = parseInt(time)
    }
    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
    return value.toString().padStart(2, '0')
  })
  return time_str
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000
  } else {
    time = +time
  }
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return (
      d.getMonth() +
            1 +
            '月' +
            d.getDate() +
            '日' +
            d.getHours() +
            '时' +
            d.getMinutes() +
            '分'
    )
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function getQueryObject(url) {
  url = url == null ? window.location.href : url
  const search = url.substring(url.lastIndexOf('?') + 1)
  const obj = {}
  const reg = /([^?&=]+)=([^?&=]*)/g
  search.replace(reg, (rs, $1, $2) => {
    const name = decodeURIComponent($1)
    let val = decodeURIComponent($2)
    val = String(val)
    obj[name] = val
    return rs
  })
  return obj
}

/**
 * @param {string} input value
 * @returns {number} output value
 */
export function byteLength(str) {
  // returns the byte length of an utf8 string
  let s = str.length
  for (var i = str.length - 1; i >= 0; i--) {
    const code = str.charCodeAt(i)
    if (code > 0x7f && code <= 0x7ff) s++
    else if (code > 0x7ff && code <= 0xffff) s += 2
    if (code >= 0xDC00 && code <= 0xDFFF) i--
  }
  return s
}

/**
 * @param {Array} actual
 * @returns {Array}
 */
export function cleanArray(actual) {
  const newArray = []
  for (let i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i])
    }
  }
  return newArray
}

/**
 * @param {Object} json
 * @returns {Array}
 */
export function param(json) {
  if (!json) return ''
  return cleanArray(
    Object.keys(json).map(key => {
      if (json[key] === undefined) return ''
      return encodeURIComponent(key) + '=' + encodeURIComponent(json[key])
    })
  ).join('&')
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  const search = url.split('?')[1]
  if (!search) {
    return {}
  }
  return JSON.parse(
    '{"' +
        decodeURIComponent(search)
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"')
          .replace(/\+/g, ' ') +
        '"}'
  )
}

/**
 * @param {string} val
 * @returns {string}
 */
export function html2Text(val) {
  const div = document.createElement('div')
  div.innerHTML = val
  return div.textContent || div.innerText
}

/**
 * Merges two objects, giving the last one precedence
 * @param {Object} target
 * @param {(Object|Array)} source
 * @returns {Object}
 */
export function objectMerge(target, source) {
  if (typeof target !== 'object') {
    target = {}
  }
  if (Array.isArray(source)) {
    return source.slice()
  }
  Object.keys(source).forEach(property => {
    const sourceProperty = source[property]
    if (typeof sourceProperty === 'object') {
      target[property] = objectMerge(target[property], sourceProperty)
    } else {
      target[property] = sourceProperty
    }
  })
  return target
}

/**
 * @param {HTMLElement} element
 * @param {string} className
 */
export function toggleClass(element, className) {
  if (!element || !className) {
    return
  }
  let classString = element.className
  const nameIndex = classString.indexOf(className)
  if (nameIndex === -1) {
    classString += '' + className
  } else {
    classString =
            classString.substr(0, nameIndex) +
            classString.substr(nameIndex + className.length)
  }
  element.className = classString
}

/**
 * @param {string} type
 * @returns {Date}
 */
export function getTime(type) {
  if (type === 'start') {
    return new Date().getTime() - 3600 * 1000 * 24 * 90
  } else {
    return new Date(new Date().toDateString())
  }
}

/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result

  const later = function() {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) context = args = null
      }
    }
  }

  return function(...args) {
    context = this
    timestamp = +new Date()
    const callNow = immediate && !timeout
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait)
    if (callNow) {
      result = func.apply(context, args)
      context = args = null
    }

    return result
  }
}

/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 * @param {Object} source
 * @returns {Object}
 */
export function deepClone(source) {
  if (!source && typeof source !== 'object') {
    throw new Error('error arguments', 'deepClone')
  }
  const targetObj = source.constructor === Array ? [] : {}
  Object.keys(source).forEach(keys => {
    if (source[keys] && typeof source[keys] === 'object') {
      targetObj[keys] = deepClone(source[keys])
    } else {
      targetObj[keys] = source[keys]
    }
  })
  return targetObj
}

/**
 * @param {Array} arr
 * @returns {Array}
 */
export function uniqueArr(arr) {
  return Array.from(new Set(arr))
}

/**
 * @returns {string}
 */
export function createUniqueString() {
  const timestamp = +new Date() + ''
  const randomNum = parseInt((1 + Math.random()) * 65536) + ''
  return (+(randomNum + timestamp)).toString(32)
}

/**
 * Check if an element has a class
 * @param {HTMLElement} elm
 * @param {string} cls
 * @returns {boolean}
 */
export function hasClass(ele, cls) {
  return !!ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'))
}

/**
 * Add class to element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function addClass(ele, cls) {
  if (!hasClass(ele, cls)) ele.className += ' ' + cls
}

/**
 * Remove class from element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function removeClass(ele, cls) {
  if (hasClass(ele, cls)) {
    const reg = new RegExp('(\\s|^)' + cls + '(\\s|$)')
    ele.className = ele.className.replace(reg, ' ')
  }
}

/**
 * @param { Object }
 * @description 从 JSON 对象删除除了指定属性之外的任何其他属性
 */
export const cleanObj = (obj, keysToKeep = [], childIndicator) => {
  Object.keys(obj).forEach(key => {
    if (key === childIndicator) {
      cleanObj(obj[key], keysToKeep, childIndicator)
    } else if (!keysToKeep.includes(key)) {
      delete obj[key]
    }
  })
  return obj
}

/**
 * @param { Object, arguments }
 * @description 从 JSON 对象删除指定属性
 */

export const delKey = (obj, ...args) => {
  args.forEach(v => {
    delete obj[v]
  })
  return obj
}

export function getArrayByKey(object, key) {
  const values = []
  object.map(item => {
    if (item[key] !== undefined) {
      values.push(item[key])
    }
  })
  return values
}
export function downloads(url, name) { // 返回下载链接就直接打开返回文件就用转文件流下载
  if (!url) {
    return
  }
  // const url = window.URL.createObjectURL(new Blob([data]))
  const link = document.createElement('a')
  link.style.display = 'none'
  link.href = url
  link.setAttribute('download', `${name}.xlsx`)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  window.URL.revokeObjectURL(url)
}

export function formatNum(strNum) {
  if (!strNum) {
    return 0
  }
  if (strNum.length <= 3) {
    return strNum
  }
  if (!/^(\+|-)?(\d+)(\.\d+)?$/.test(strNum)) {
    return strNum
  }
  var a = RegExp.$1
  var b = RegExp.$2
  var c = RegExp.$3
  var re = new RegExp()
  re.compile('(\\d)(\\d{3})(,|$)')
  while (re.test(b)) {
    b = b.replace(re, '$1,$2$3')
  }
  return a + '' + b + '' + c
}

// 去除千分位
export function delcommafy(num) {
  if (num) {
    return num.replace(/,/gi, '')
  }
}

export function preText(pretext, toArray = false, limit = true, message = '最大输入50条数据',) {
  const arr = []
  const array = pretext && pretext.replace(/\r\n/g, '<br/>').replace(/\n/g, ',').replace(/\s/g, '&nbsp;').split(',') || []
  array.map(item => {
    if (item) {
      arr.push(item)
    }
  })
  if (limit && arr.length > 50) {
    this.$message({
      message,
      type: 'warning'
    })
    return
  }
  return toArray ? arr : arr.join(',')
}
export function isJson(str) {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export function handleDownload(res, fileName) {
  const url = window.URL.createObjectURL(new Blob([res], { type: 'application/octet-stream;charset=utf-8' }))
  const link = document.createElement('a')
  link.style.display = 'none'
  link.href = url
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
  window.URL.revokeObjectURL(link.href)
}

export function handelplatSiteShop(arr) {
  const platSiteShopList = []
  Array.isArray(arr) && arr.map(list => {
    const [plat, site, shop] = list
    const [platformId, platformName] = plat.split('&&')
    const [siteId, siteCode] = site.split('&&')
    const [shopId, shopCode] = shop.split('&&')
    platSiteShopList.push({
      platformId, platformName,
      siteId, siteCode,
      shopId, shopCode
    })
  })
  return platSiteShopList
}

export function handleMonitorParams(params = {}) {
  return Object.fromEntries(
    Object.entries(params).filter(([key, value]) => {
      return (typeof value === 'string' || typeof value === 'number' && value || Array.isArray(value) && value.length)
    })
  )
}

export function handlePageParams(options = {},) {
  const { formData, processedParameter:
    {
      timeField,
      findNameOrIdList,
      preTextArr = [],
      toArrayList = [],
      lastCategoryIdList = [],
      platSiteShopList = [],
      toStringList = []
    }, extraParams = {}
  } = options
  const newData = cloneDeep(formData)

  if (timeField) {
    const keys = Object.keys(timeField)
    const values = Object.values(timeField)
    keys.forEach((v, idx) => {
      const [start, end] = values[idx]
    ;[newData[start], newData[end]] = newData[v] || ['', '']
      if (values[idx].indexOf(v) === -1) {
        Reflect.deleteProperty(newData, v)
      }
    })
  }
  if (findNameOrIdList) {
    const keys = Object.keys(findNameOrIdList)
    const values = Object.values(findNameOrIdList)
    keys.forEach((v, idx) => {
      const [field, defaultField, name, list] = values[idx]
      newData[v] = list?.find(v => v[defaultField] === newData[field])?.[name]
    })
  }
  if (preTextArr) {
    Object.entries(preTextArr).forEach(([key, value]) => {
      newData[key] = preText(newData[key], value)
    })
  }
  if (lastCategoryIdList && lastCategoryIdList.length) {
    lastCategoryIdList.forEach((v) => {
      newData[v] = Array.isArray(newData[v]) && newData[v].flatMap(v => v.slice(-1)).filter(Boolean) || []
    })
  }
  if (platSiteShopList && platSiteShopList.length) {
    platSiteShopList.forEach((v) => {
      newData[v] = newData[v] && handelplatSiteShop(newData[v])
    })
  }
  if (toArrayList && toArrayList.length) {
    toArrayList.forEach((v) => {
      newData[v] = newData[v] && [newData[v]] || []
    })
  }
  if (toStringList && toStringList.length) {
    toStringList.forEach((v) => {
      newData[v] = newData[v] && newData[v].toString() || ''
    })
  }
  return { ...newData, ...options.pager, ...extraParams }
}
