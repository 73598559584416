import router from './router'
import store from './store'
import { Message } from 'element-ui'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { setToken, setTokenByKey, JWT_TOKEN, WG_OAUTH_REFRESH, WG_OAUTH } from '@/utils/auth' // get token from cookie
import getPageTitle from '@/utils/get-page-title'
import { getMenus } from '@/api/user'
import { omit } from 'lodash'
NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/auth-redirect', '/inner-dashboard', '/inner-stock-manage', '/vendor/reconcile'] // no redirect whitelist

router.beforeEach(async(to, from, next) => {
  NProgress.start()

  // 设置页面Title
  document.title = getPageTitle(to.meta.title)

  // sso token
  const { jwt_token, wg_oauth_refresh } = to.query
  if (jwt_token) {
    // iframe的token传参字段为token，sso传参字段为wg_oauth
    const token = to.query.token || to.query.wg_oauth
    console.log('to.query.wg_oauth: ', to.query.wg_oauth)
    console.log('token: ', token)
    if (!token) {
      Message({
        message: '您的飞书账号暂未与WMS系统账号映射，请使用用户名密码登录业务系统',
        type: 'error',
        duration: 3 * 1000
      })
      let redirect_uri = location.href.split('?')[0] + '?'
      for (const [key, value] of Object.entries(to.query)) {
        if (key && key !== JWT_TOKEN) redirect_uri += `${key}=${value}&`
      }
      setTimeout(() => {
        location.href = `${process.env.VUE_APP_RIGHTS_API}auth/loginPage?redirect_uri=${redirect_uri}`
      }, 3000)
      return
    }
    setToken(token)
    setTokenByKey(JWT_TOKEN, jwt_token)
    setTokenByKey(WG_OAUTH_REFRESH, wg_oauth_refresh)
    store.commit('user/SET_JWT_TOKEN', jwt_token)
    store.commit('user/SET_REFRESH_TOKEN', wg_oauth_refresh)
    store.commit('user/SET_TOKEN', token)
    const query = omit(to.query, JWT_TOKEN, WG_OAUTH_REFRESH, WG_OAUTH)
    next({ path: to.path, query, replace: true })
  } else {
    if (whiteList.indexOf(to.path) !== -1 || to.path.includes('/iframe/')) {
      next()
      NProgress.done()
    } else {
      if (store.getters.userId) {
        const permissionRoutes = store.getters.permission_routes
        const { length, last = length - 1 } = permissionRoutes
        const hasMenu = permissionRoutes && permissionRoutes[last] && permissionRoutes[last].path === '*'
        if (hasMenu) {
          next()
        } else {
          await store.dispatch('user/resetToken')
          location.href = `${process.env.VUE_APP_SSO_REDIRECT_URL}?_fromApp=auth-server&redirect_uri=${location.href}`
          return
        }
      } else {
        try {
          await store.dispatch('user/getInfo')
          const { datas } = await getMenus()
          const accessRoutes = await store.dispatch('permission/createRoutes', datas)
          router.addRoutes(accessRoutes)
          next({ ...to, replace: true })
        } catch (error) {
          console.log(error)
        }
      }
    }
  }
})

router.afterEach((to) => {
  // 动态获取route.meta.permissions
  !window.location.href.includes('/iframe') && store.dispatch('permission/updataPermissions', to.meta.permissions)
  NProgress.done()
})

