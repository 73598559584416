'use strict'
function _interopDefault(ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex }

var constants = require('./constants')
var utils = require('./utils')
var utilsLite = require('utils-lite')
require('echarts/lib/chart/pie')
var Core = _interopDefault(require('./core'))
var _extends = Object.assign || function(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i]

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key]
      }
    }
  }

  return target
}

var pieRadius = 100
var ringRadius = [80, 100]
var roseRingRadius = [20, 100]
var pieOffsetY = 200

function getPieSeries(args) {
  var _this = this
  var innerRows = args.innerRows
  var dataType = args.dataType
  var percentShow = args.percentShow
  var dimension = args.dimension
  var metrics = args.metrics
  var radius = args.radius
  var offsetY = args.offsetY
  var selectedMode = args.selectedMode
  var hoverAnimation = args.hoverAnimation
  var digit = args.digit
  var roseType = args.roseType
  var label = args.label
  var level = args.level
  var limitShowNum = args.limitShowNum
  var isRing = args.isRing
  var labelLine = args.labelLine
  var itemStyle = args.itemStyle

  var series = []
  var levelTemp = {}
  var rowsTemp = []
  if (level) {
    level.forEach(function(levelItems, index) {
      levelItems.forEach(function(item) {
        utils.setArrayValue(levelTemp, item, index)
      })
    })
    innerRows.forEach(function(row) {
      var itemLevel = levelTemp[row[dimension]]
      if (itemLevel && itemLevel.length) {
        itemLevel.forEach(function(levelItem) {
          utils.setArrayValue(rowsTemp, levelItem, row)
        })
      }
    })
  } else {
    rowsTemp.push(innerRows)
  }
  var seriesBase = {
    type: 'pie',
    selectedMode: selectedMode,
    hoverAnimation: hoverAnimation,
    roseType: roseType,
    center: ['50%', offsetY]
  }
  var rowsTempLength = rowsTemp.length
  rowsTemp.forEach(function(dataRows, index) {
    var seriesItem = _extends({ data: [] }, seriesBase)
    var centerWidth = radius / rowsTempLength
    if (!index) {
      seriesItem.radius = isRing ? radius : centerWidth
    } else {
      var outerWidth = centerWidth + radius / (2 * rowsTempLength) * (2 * index - 1)
      var innerWidth = outerWidth + radius / (2 * rowsTempLength)
      seriesItem.radius = [outerWidth, innerWidth]
    }
    if (rowsTempLength > 1 && index === 0) {
      seriesItem.label = {
        normal: { position: 'inner' }
      }
    }
    if (label) seriesItem.label = label
    if (labelLine) seriesItem.labelLine = labelLine
    if (itemStyle) seriesItem.itemStyle = itemStyle
    if (percentShow) {
      seriesItem.label = {
        normal: {
          show: true,
          position: rowsTempLength > 1 && index === 0 ? 'inner' : 'outside',
          formatter: function formatter(item) {
            var tpl = []
            tpl.push(item.name + ':')
            tpl.push(utils.getFormated(item.value, dataType, digit))
            tpl.push('(' + item.percent + '%)')
            return tpl.join(' ')
          }
        }
      }
    }
    seriesItem.data = dataRows.map(function(row) {
      return {
        name: row[dimension],
        value: row[metrics]
      }
    })
    series.push(seriesItem)
  })
  if (limitShowNum && limitShowNum < series[0].data.length) {
    var firstData = series[0].data
    var remainArr = firstData.slice(limitShowNum, firstData.length)
    var sum = 0
    remainArr.forEach(function(item) {
      sum += item.value
    })
    series[0].data = firstData.slice(0, limitShowNum)
    series[0].data.push({ name: _this.$t('page.others'), value: sum })
  }
  return series
}

function getPieLegend(args) {
  var _this = this
  var innerRows = args.innerRows
  var dimension = args.dimension
  var legendLimit = args.legendLimit
  var legendName = args.legendName
  var level = args.level
  var limitShowNum = args.limitShowNum

  var legend = []
  var levelTemp = []
  if (level) {
    level.forEach(function(levelItem) {
      levelItem.forEach(function(item) {
        levelTemp.push(item)
      })
    })
    legend = levelTemp
  } else if (limitShowNum && limitShowNum < innerRows.length) {
    for (var i = 0; i < limitShowNum; i++) {
      legend.push(innerRows[i][dimension])
    }
    legend.push(_this.$t('page.others'))
  } else {
    legend = innerRows.map(function(row) {
      return row[dimension]
    })
  }
  if (legend.length) {
    return {
      type: 'scroll',
      data: legend,
      show: legend.length < legendLimit,
      formatter: function formatter(name) {
        return legendName[name] != null ? legendName[name] : name
      }
    }
  } else {
    return false
  }
}

function getPieTooltip(args) {
  var _this = this
  var dataType = args.dataType
  var innerRows = args.innerRows
  var limitShowNum = args.limitShowNum
  var digit = args.digit
  var metrics = args.metrics
  var dimension = args.dimension

  var sum = 0
  var remainArr = innerRows.map(function(row) {
    sum += row[metrics]
    return {
      name: row[dimension],
      value: row[metrics]
    }
  }).slice(limitShowNum, innerRows.length)
  return {
    formatter: function formatter(item) {
      var tpl = []
      tpl.push(constants.itemPoint(item.color))
      if (limitShowNum && item.name === _this.$t('page.others')) {
        tpl.push(`${_this.$t('page.others')}:`)
        remainArr.forEach(function(_ref) {
          var name = _ref.name
          var value = _ref.value

          var percent = utils.getFormated(value / sum, 'percent')
          tpl.push('<br>' + name + ':')
          tpl.push(utils.getFormated(value, dataType, digit))
          tpl.push('(' + percent + ')')
        })
      } else {
        tpl.push(item.name + ':')
        tpl.push(utils.getFormated(item.value, dataType, digit))
        tpl.push('(' + item.percent + '%)')
      }
      return tpl.join(' ')
    }
  }
}

var pie$1 = function pie$$1(columns, rows, settings, extra, isRing) {
  var innerRows = utilsLite.cloneDeep(rows)
  var _settings$dataType = settings.dataType
  var dataType = _settings$dataType === undefined ? 'normal' : _settings$dataType
  var percentShow = settings.percentShow
  var _settings$dimension = settings.dimension
  var dimension = _settings$dimension === undefined ? columns[0] : _settings$dimension
  var _settings$metrics = settings.metrics
  var metrics = _settings$metrics === undefined ? columns[1] : _settings$metrics
  var _settings$roseType = settings.roseType
  var roseType = _settings$roseType === undefined ? false : _settings$roseType
  var _settings$radius = settings.radius
  var radius = _settings$radius === undefined ? isRing ? roseType ? roseRingRadius : ringRadius : pieRadius : _settings$radius
  var _settings$offsetY = settings.offsetY
  var offsetY = _settings$offsetY === undefined ? pieOffsetY : _settings$offsetY
  var _settings$legendLimit = settings.legendLimit
  var legendLimit = _settings$legendLimit === undefined ? 30 : _settings$legendLimit
  var _settings$selectedMod = settings.selectedMode
  var selectedMode = _settings$selectedMod === undefined ? false : _settings$selectedMod
  var _settings$hoverAnimat = settings.hoverAnimation
  var hoverAnimation = _settings$hoverAnimat === undefined ? true : _settings$hoverAnimat
  var _settings$digit = settings.digit
  var digit = _settings$digit === undefined ? 2 : _settings$digit
  var _settings$legendName = settings.legendName
  var legendName = _settings$legendName === undefined ? {} : _settings$legendName
  var _settings$label = settings.label
  var label = _settings$label === undefined ? false : _settings$label
  var _settings$level = settings.level
  var level = _settings$level === undefined ? false : _settings$level
  var _settings$limitShowNu = settings.limitShowNum
  var limitShowNum = _settings$limitShowNu === undefined ? 0 : _settings$limitShowNu
  var labelLine = settings.labelLine
  var itemStyle = settings.itemStyle
  var tooltipVisible = extra.tooltipVisible
  var legendVisible = extra.legendVisible

  if (limitShowNum) {
    innerRows.sort(function(a, b) {
      return b[metrics] - a[metrics]
    })
  }
  var seriesParams = {
    innerRows: innerRows,
    dataType: dataType,
    percentShow: percentShow,
    dimension: dimension,
    metrics: metrics,
    radius: radius,
    offsetY: offsetY,
    selectedMode: selectedMode,
    hoverAnimation: hoverAnimation,
    digit: digit,
    roseType: roseType,
    label: label,
    level: level,
    legendName: legendName,
    limitShowNum: limitShowNum,
    isRing: isRing,
    labelLine: labelLine,
    itemStyle: itemStyle
  }
  var series = getPieSeries.bind(this)(seriesParams)
  var legendParams = {
    innerRows: innerRows,
    dimension: dimension,
    legendLimit: legendLimit,
    legendName: legendName,
    level: level,
    limitShowNum: limitShowNum
  }
  var legend = legendVisible && getPieLegend.bind(this)(legendParams)
  var tooltip = tooltipVisible && getPieTooltip.bind(this)({
    dataType: dataType,
    innerRows: innerRows,
    limitShowNum: limitShowNum,
    digit: digit,
    metrics: metrics,
    dimension: dimension
  })
  var options = { series: series, legend: legend, tooltip: tooltip }
  return options
}

var VePie = _extends({}, Core, {
  name: 'VePie',
  data: function data() {
    this.chartHandler = pie$1
    return {}
  }
})

export default VePie
