import request from '@/utils/scm-request'
import requestWms from '@/utils/request'
/**
 *
 * @description
 * @export Promise
 * @returns Json
 */
// 文件上传
export function uploadFile(data) {
  return request({
    url: `scm-purchase/basic/uploadFile`,
    method: 'post',
    data
  })
}
// 生成鞋印批次号
export function generateShoeBatchNo(data) {
  return request({
    url: `scm-purchase/order/generateShoeBatchNo`,
    method: 'post',
    data
  })
}
// 生成鞋印批次号
export function splitShoeBatchNo(data) {
  return request({
    url: `scm-purchase/order/splitShoeBatchNo`,
    method: 'post',
    data
  })
}
// 质检管理查询
export function qualitypage(params) {
  return request({
    url: `scm-purchase/quality/page`,
    method: 'get',
    params
  })
}
// 质检提交
export function qualitysubmit(data) {
  return request({
    url: `scm-purchase/quality/submit`,
    method: 'post',
    data
  })
}
// 质检追加图片提交
export function qualityAddFile(data) {
  return request({
    url: `scm-purchase/quality/addFile`,
    method: 'PUT',
    data
  })
}
// 质检详情
export function qualitydetail(id) {
  return request({
    url: `scm-purchase/quality/${id}`,
    method: 'get'
  })
}

// Turnover管理
// Turnover管理列表查询
export function turnoverpage(params) {
  return request({
    url: `scm-purchase/turnover/page`,
    method: 'get',
    params
  })
}

// Turnover管理列表查询价格新增
export function addturnover(data) {
  return request({
    url: `scm-purchase/turnover`,
    method: 'post',
    data
  })
}

// Turnover管理列表查询价格修改
export function modifyturnover(data) {
  return request({
    url: `scm-purchase/turnover`,
    method: 'put',
    data
  })
}

// Turnover管理列表查询通过id查询
export function category(style) {
  return request({
    url: `scm-purchase/turnover/category/${style}`,
    method: 'get'
  })
}
// Turnover管理列表查询通过id查询
export function getturnoverid(id) {
  return request({
    url: `scm-purchase/turnover/${id}`,
    method: 'get'
  })
}
// Turnover管理列表查询通过id删除
export function deleteturnover(id) {
  return request({
    url: `scm-purchase/turnover/${id}`,
    method: 'delete'
  })
}
// 根据sku/upc/fnsku或者style&color&size查询SKU基础信息
export function getSkuInfo(params) {
  return request({
    url: `scm-purchase/price/getSkuInfo`,
    method: 'get',
    params
  })
}
// 列表查询
export function pricepage(params) {
  return request({
    url: `scm-purchase/price/page`,
    method: 'get',
    params
  })
}

// 价格新增
export function addpurchaseprice(data) {
  return request({
    url: `scm-purchase/price`,
    method: 'post',
    data
  })
}

// 导入新增
export function addturnoverbatch(data) {
  return request({
    url: `scm-purchase/turnover/batch`,
    method: 'post',
    data
  })
}
// 导入新增
export function addpurchasebatch(data) {
  return request({
    url: `scm-purchase/price/batch`,
    method: 'post',
    data
  })
}
// 价格修改
export function modifypurchaseprice(data) {
  return request({
    url: `scm-purchase/price`,
    method: 'put',
    data
  })
}
// 通过id查询
export function getpriceid(id) {
  return request({
    url: `scm-purchase/price/${id}`,
    method: 'get'
  })
}
// 通过id删除
export function deleteprice(id) {
  return request({
    url: `scm-purchase/price/${id}`,
    method: 'delete'
  })
}
// 保存采购计划
export function addpurchaseplan(data) {
  return request({
    url: `scm-purchase/plan`,
    method: 'post',
    data
  })
}
// 修改采购计划
export function modifypurchaseplan(data) {
  return request({
    url: `scm-purchase/plan`,
    method: 'put',
    data
  })
}
// 批量新增确认返回数据
export function addpurchaseconfirm(data) {
  return request({
    url: `scm-purchase/plan/confirm`,
    method: 'post',
    data
  })
}
// 采购计划分页查询
export function purchaseplanpage(params) {
  return request({
    url: `scm-purchase/plan/page`,
    method: 'get',
    params
  })
}

// 分页查询SKU基础信息
export function listSkuInfoByPage(params) {
  return request({
    url: `scm-purchase/price/listSkuInfoByPage`,
    method: 'get',
    params
  })
}

// 通过id查询
export function purchaseplanid(id) {
  return request({
    url: `scm-purchase/plan/${id}`,
    method: 'get'
  })
}
// 采购计划单转采购订单校验
export function purchasePlanToOrderCheck(data) {
  return request({
    url: `scm-purchase/plan/purchasePlanToOrderCheck`,
    method: 'post',
    data
  })
}
// 缺量完成采购计划
export function purchasePlanShortage(data) {
  return request({
    url: `scm-purchase/plan/shortage`,
    method: 'post',
    data
  })
}

// 查看转单明细
export function queryPlanToOrder(params) {
  return request({
    url: `scm-purchase/plan/queryPlanToOrder`,
    method: 'get',
    params
  })
}

// 作废采购计划
export function deleteplanid(id) {
  return request({
    url: `scm-purchase/plan/cancel/${id}`,
    method: 'post'
  })
}
// 提交采购计划
export function purchaseplansubmit(data) {
  return request({
    url: `scm-purchase/plan/submit`,
    method: 'put',
    data
  })
}
// 采购计划明细选择供应商提交
export function submitPurchasePlanByVendor(data) {
  return request({
    url: `scm-purchase/plan/submitPurchasePlanByVendor`,
    method: 'post',
    data
  })
}
// http://192.168.1.216:7100
// 采购计划单转采购订单提交保存
export function purchasePlanToOrder(data) {
  return request({
    url: `scm-purchase/plan/purchasePlanToOrder`,
    method: 'post',
    data
  })
}
// 采购计划单转采购订单预览
export function purchasePlanToOrderPreview(data) {
  return request({
    url: `scm-purchase/plan/purchasePlanToOrderPreview`,
    method: 'post',
    data
  })
}
// 取消采购订单
export function cancelPurchaseOrder(data) {
  return request({
    url: `scm-purchase/order/cancelOrder`,
    method: 'post',
    data
  })
}
// 批量修改采购价格
export function updatePriceBatch(data) {
  return request({
    url: `scm-purchase/price/updatePriceBatch`,
    method: 'post',
    data
  })
}

// 采购订单分页查询
export function orderpage(params) {
  return request({
    url: `scm-purchase/order/page`,
    method: 'get',
    params
  })
}
// 打印鞋标 - 采购订单明细查询
export function listDetailByPrint(params) {
  return request({
    url: `scm-purchase/order/listDetailByPrint`,
    method: 'get',
    params
  })
}
// 打印鞋标 - 打印
export function printShoes(data) {
  return request({
    url: `scm-purchase/order/printShoesCode`,
    method: 'post',
    data
  })
}
// 供应商交期记录
export function VendorDeliveryFeedback(purchaseOrderCode) {
  return request({
    url: `scm-purchase/order/listVendorDeliveryFeedbackLog/${purchaseOrderCode}`,
    method: 'get'
  })
}

// 转单
export function purchaseOrderToCollect(data) {
  return request({
    url: `scm-purchase/order/purchaseOrderToCollect`,
    method: 'post',
    data
  })
}

// 审批流分页
export function approvalpage(params) {
  return request({
    url: `scm-purchase/approval/page`,
    method: 'get',
    params
  })
}
// 审批流提交
export function approvalsubmit(data) {
  return request({
    url: `scm-purchase/approval/submit`,
    method: 'post',
    data
  })
}
// 审批流详情
export function approvalDetail(params) {
  return request({
    url: `scm-purchase/approval/getDetail`,
    method: 'get',
    params
  })
}
// 导入详情
export function priceimport(data) {
  return request({
    url: `scm-purchase/price/import`,
    method: 'post',
    data
  })
}
// 导入详情
export function turnoverimport(data) {
  return request({
    url: `scm-purchase/turnover/import`,
    method: 'post',
    data
  })
}
// 交期反馈确认提交导入预览
export function deliveryFeedbackImportView(data) {
  return request({
    url: `scm-purchase/order/deliveryFeedbackImportView`,
    method: 'post',
    data
  })
}
// 交期反馈确认提交导入详情
export function deliveryFeedbackImport(data) {
  return request({
    url: `scm-purchase/order/deliveryFeedbackImport`,
    method: 'post',
    data
  })
}
// 采购计划导入详情
export function planimport(data) {
  return request({
    url: `scm-purchase/plan/import`,
    method: 'post',
    data
  })
}

// 生产进度查询
export function produceProgresspage(params) {
  return request({
    url: `scm-purchase/produceProgress/page`,
    method: 'get',
    params
  })
}
// 修改已完成数量
export function updateFinishPairs(data) {
  return request({
    url: `scm-purchase/produceProgress/updateFinishPairs`,
    method: 'put',
    data
  })
}
// 分配供应商生产
export function allocationVendorProd(data) {
  return request({
    url: `scm-purchase/order/allocationVendorProd`,
    method: 'post',
    data
  })
}
// 修改供应商交期反馈
export function updateVendorDeliveryFeedback(data) {
  return request({
    url: `scm-purchase/order/updateVendorDeliveryFeedback`,
    method: 'put',
    data
  })
}

// 通过id查询
export function vieworderId(id) {
  return request({
    url: `scm-purchase/order/${id}`,
    method: 'get'
  })
}
// 作废采购订单
export function deleteorderid(id) {
  return request({
    url: `scm-purchase/order/${id}`,
    method: 'delete'
  })
}

// 修改
export function purchaseorder(data) {
  return request({
    url: `scm-purchase/order/update`,
    method: 'put',
    data
  })
}
// 打印合同
export function printContract(purchaseorder) {
  return request({
    url: `scm-purchase/order/printContract/${purchaseorder}`,
    method: 'get'
  })
}
// 合同导出
export function exportContract(purchaseOrderCode) {
  return request({
    url: `scm-purchase/order/exportContract/${purchaseOrderCode}`,
    method: 'get',
    timeout: 9999999
  })
}

// 采购台账
export function purchaseAccountPage(params) {
  return request({
    url: `scm-purchase/statistics/purchaseAccountPage`,
    method: 'get',
    params
  })
}
// 修改未结
export function updateOrderOutStockInfo(data) {
  return request({
    url: `scm-purchase/order/updateOrderOutStockInfo`,
    method: 'post',
    data
  })
}

// 未结订单
export function unclearedOrderPage(params) {
  return request({
    url: `scm-purchase/statistics/unclearedOrderPage`,
    method: 'get',
    params
  })
}
// 未结订单带图片导出校验
export function basicExportCheck() {
  return request({
    url: `scm-purchase/basic/exportCheck`,
    method: 'get'
  })
}

// 未结订单 获取站点平台
export function getPlatFormAndSiteNumberByUncleared(params) {
  return request({
    url: `scm-purchase/statistics/getPlatFormAndSiteNumberByUncleared`,
    method: 'get',
    params
  })
}
// 未结订单 运营、采购反馈意见查询
export function listOpinionFeedbackLog(purchaseOrderCode, type) {
  return request({
    url: `scm-purchase/order/listOpinionFeedbackLog/${purchaseOrderCode}/${type}`,
    method: 'get'
  })
}

// 获取站点平台  已弃用
export function getPlatFormAndSiteNumber(params) {
  return request({
    url: `scm-purchase/order/getPlatFormAndSiteNumber`,
    method: 'get',
    params
  })
}
// 供应商报价分页查询
export function getVendorOffer(params) {
  return request({
    url: `scm-purchase/vendorOffer/page`,
    method: 'get',
    params
  })
}

// 供应商报价详情分页查询
export function getVendorOfferDetail(params) {
  return request({
    url: `scm-purchase/vendorOffer/pageDetail`,
    method: 'get',
    params
  })
}
// 批量新增供应商查询基础数据信息
export function getPageByBatchInsert(params) {
  return request({
    url: `scm-purchase/vendorOffer/pageByBatchInsert`,
    method: 'get',
    params
  })
}
// 预览供应商
export function vendorOfferImport(data) {
  return request({
    url: `scm-purchase/vendorOffer/vendorOfferImport`,
    method: 'post',
    data
  })
}

// 预览供应商提交
export function vendorOfferImportView(data) {
  return request({
    url: `scm-purchase/vendorOffer/vendorOfferImportView`,
    method: 'post',
    data
  })
}

// 保存供应商报价
export function vendorOfferSave(data) {
  return request({
    url: `scm-purchase/vendorOffer/save`,
    method: 'post',
    data
  })
}
// 提交供应商报价
export function vendorOfferSubmit(data) {
  return request({
    url: `scm-purchase/vendorOffer/submit`,
    method: 'post',
    data
  })
}
// 修改带id查询
export function getVendorOfferId(id) {
  return request({
    url: `scm-purchase/vendorOffer/${id}`,
    method: 'get'
  })
}

//  获取站点平台 基础信息
export function listPlatformAndSite() {
  return request({
    url: `scm-purchase/basic/listPlatformAndSite`,
    method: 'get'
  })
}
//  buoperator
export function listBuAndOperator() {
  return request({
    url: `scm-purchase/basic/listBuAndOperator`,
    method: 'get'
  })
}
// 类别树查询
export function getBaseCategory(params) {
  return requestWms({
    url: `/base/basecategory/tree`,
    method: 'get',
    params
  })
}
// 季节下拉框
export function querySeasonList() {
  return request({
    url: `/scm-purchase/basic/getSeason`,
    method: 'get'
  })
}

// Style信息查询
export function queryStyleList() {
  return requestWms({
    url: `/base/basestyle/list`,
    method: 'get'
  })
}
// 带权限的用户style查询
export function LimitedqueryStyleList() {
  return request({
    url: `/scm-purchase/basic/getUserStyleList?styleStatus=1`,
    method: 'get'
  })
}

// 通过Style ID查询sku生成需要的数据
export function querySkuListByStyle(id) {
  return requestWms({
    url: `/base/basesku/queryStyleInfoAndAttr/${id}`,
    method: 'get'
  })
}
// Size信息查询
export function querySizeList(params) {
  return requestWms({
    url: `/base/basesize/list`,
    method: 'get',
    params
  })
}
// Color信息查询
export function queryColorList(params) {
  return requestWms({
    url: `/base/basecolor/list`,
    method: 'get',
    params
  })
}
export function queryVendorList(params) {
  return requestWms({
    url: `/base/basevendor/list`,
    method: 'get',
    params
  })
}
// 类别条件查询
export function queryCategorylist(params) {
  return requestWms({
    url: `/base/basecategory/list`,
    method: 'get',
    params
  })
}

// 站点条件查询
export function querySiteList(params) {
  return requestWms({
    url: `/base/basesite/list`,
    method: 'get',
    params
  })
}
// 平台条件查询
export function queryTerraceList(params) {
  return requestWms({
    url: `/base/baseterrace/list`,
    method: 'get',
    params
  })
}
// 通过平台查询站点
export function listByPlatform(params) {
  return requestWms({
    url: `/base/basesite/listByPlatform`,
    method: 'get',
    params
  })
}
/** 模板下载 */
export function findDownloadUrl(templateKey) {
  return requestWms({
    url: `/base/templatedownloadtask/findDownloadUrl/${templateKey}`,
    method: 'get'
  })
}
// 仓库信息查询
export function queryWarehouseList(params) {
  return requestWms({
    url: `/wms-base/basewarehouse/listAll`,
    method: 'get',
    params
  })
}
// 1016 IMS物理仓
export function getPhysicsWarehouseList(params) {
  return request({
    url: `/scm-purchase/basic/getImsPhysicsWarehouseList`,
    method: 'get',
    params
  })
}
// 1024 IMS全量仓库
export function getImsWarehouseList(params) {
  return request({
    url: `/scm-purchase/basic/getImsWarehouseList`,
    method: 'get',
    params
  })
}
// 导出
export function ExcelExport(data) {
  return requestWms({
    url: `/excel/export`,
    method: 'post',
    data
  })
}

// 员工信息
export function usersList(params) {
  return requestWms({
    url: `/auth/users/list`,
    method: 'get',
    params
  })
}
// 未结订单标红
export function updateUnclearedOrderIsRed(data) {
  return request({
    url: `/scm-purchase/order/updateUnclearedOrderIsRed`,
    method: 'put',
    data
  })
}

// 采购计划撤销
export function revoke(id) {
  return request({
    url: `/scm-purchase/plan/revoke/${id}`,
    method: 'post'
  })
}
// 采购订单回滚
export function purchaseOrderRollBack(data) {
  return request({
    url: `/scm-purchase/order/purchaseOrderRollBack`,
    method: 'put',
    data
  })
}
// 财务模块
// 明细对账分页查询
export function notCheckBillDetailPage(params) {
  return request({
    url: `/scm-purchase/checkBill/notCheckBillDetailPage`,
    method: 'get',
    params
  })
}
// 整单对账分页查询
export function notCheckBillPage(params) {
  return request({
    url: `/scm-purchase/checkBill/notCheckBillPage`,
    method: 'get',
    params
  })
}
// 未到货未付分页查询
export function notCollectDetailPage(params) {
  return request({
    url: `/scm-purchase/checkBill/notCollectDetailPage`,
    method: 'get',
    params
  })
}
// 明细对账确认(勾选)
export function confirmCheckBillDetail(data) {
  return request({
    url: `/scm-purchase/checkBill/confirmCheckBillDetail`,
    method: 'post',
    data,
    timeout: 9999999
  })
}
// 整单对账确认
export function confirmCheckBill(data) {
  return request({
    url: `/scm-purchase/checkBill/confirmCheckBill`,
    method: 'post',
    data
  })
}
// 采购对账分页查询
export function checkBillPage(params) {
  return request({
    url: `/scm-purchase/checkBill/checkBillPage`,
    method: 'get',
    params
  })
}
// 所有用户
export function usersPage(params) {
  return requestWms({
    url: `/auth/users/list`,
    method: 'get',
    params
  })
}
// 根据ID查询采购对账详情
export function checkBillById(id) {
  return request({
    url: `/scm-purchase/checkBill/${id}`,
    method: 'get'
  })
}
// 撤销对账单
export function cancelCheckBill(data) {
  return request({
    url: `/scm-purchase/checkBill/cancelCheckBill`,
    method: 'post',
    data
  })
}

// 删除采购计划明细
export function deletePlan(data) {
  return request({
    url: `/scm-purchase/plan/deleteDetailByIds`,
    method: 'post',
    data
  })
}
// 对账复核
export function checkBillReview(data) {
  return request({
    url: `/scm-purchase/checkBill/checkBillReview`,
    method: 'post',
    data
  })
}

// 付款记录查询
export function paymentLogList(params) {
  return request({
    url: `/scm-purchase/payment/paymentLogList`,
    method: 'get',
    params
  })
}

// 付款数据分页查询
export function paymentPage(params) {
  return request({
    url: `/scm-purchase/payment/paymentPage`,
    method: 'get',
    params
  })
}
// 付款
export function payment(data) {
  return request({
    url: `/scm-purchase/payment/payment`,
    method: 'post',
    data
  })
}

// 采购退货
// 首页分页查询
export function returnPageList(params) {
  return request({
    url: `/scm-purchase/rg/page`,
    method: 'get',
    params
  })
}
// 新增分页查询
export function returnBeforePageList(params) {
  return request({
    url: `/scm-purchase/rg/beforePage`,
    method: 'get',
    params
  })
}
// 新增退货提交
export function returnSubmit(data) {
  return request({
    url: `/scm-purchase/rg/submit`,
    method: 'post',
    data,
    timeout: 720000
  })
}
// 采购退货详情
export function getReturnInfoById(id) {
  return request({
    url: `/scm-purchase/rg/getInfoById/${id}`,
    method: 'get'
  })
}
// 预备退货数据导入预览
export function returnGoodsBeforeImportView(data) {
  return request({
    url: `/scm-purchase/rg/returnGoodsBeforeImportView`,
    method: 'post',
    data
  })
}
// 0331采购订单新增接口
// 计划评审时间批量导入预览
export function planDeliveryDateImport(data) {
  return request({
    url: `/scm-purchase/order/planDeliveryDateImport`,
    method: 'post',
    data
  })
}

// 预备退货数据导入提交
export function returnGoodsBeforeImport(data) {
  return request({
    url: `/scm-purchase/rg/returnGoodsBeforeImport`,
    method: 'post',
    data
  })
}
// 计划评审时间批量导入
export function planDeliveryDateImportView(data) {
  return request({
    url: `/scm-purchase/order/planDeliveryDateImportView`,
    method: 'post',
    data
  })
}

// 作废退货单
export function cancel(id) {
  return request({
    url: `/scm-purchase/rg/cancel/${id}`,
    method: 'get'
  })
}

// 首页采购订单延期报告列表
export function homeOrderDelayPage(params) {
  return request({
    url: `/scm-purchase/statistics/orderDelayPage`,
    method: 'get',
    params
  })
}

// 0416未对账单导入
export function batchUpdateActualPriceImportView(data) {
  return request({
    url: `/scm-purchase/checkBill/batchUpdateActualPriceImportView`,
    method: 'post',
    data
  })
}
// 转仓库退货
export function convertToWmsReturnGoods(data) {
  return request({
    url: `/scm-purchase/rg/convertToWmsReturnGoods`,
    method: 'post',
    data
  })
}
// 未对账单导入详情
export function batchUpdateActualPriceImport(data) {
  return request({
    url: `/scm-purchase/checkBill/batchUpdateActualPriceImport`,
    method: 'post',
    data
  })
}
// 货主管理分页查询
export function cargoownerList(params) {
  return requestWms({
    url: `/base/cargoowner/page`,
    method: 'get',
    params
  })
}
// 新增货主
export function addCargoowner(data) {
  return requestWms({
    url: `/base/cargoowner`,
    method: 'post',
    data
  })
}

// 修改货主
export function editCargoowner(data) {
  return requestWms({
    url: `/base/cargoowner`,
    method: 'PUT',
    data
  })
}

export function getcargoownerList() {
  return requestWms({
    url: `/base/cargoowner/list`,
    method: 'get'
  })
}

// 4.20 批量修改供应商
export function batchUpdateVendor(data) {
  return request({
    url: `/scm-purchase/order/batchUpdateVendor`,
    method: 'post',
    data
  })
}
// 0425明确明细确认（不勾选）

// 明确明细确认（不勾选）
export function confirmCheckBillDetailByNotCheck(data) {
  return request({
    url: `/scm-purchase/checkBill/confirmCheckBillDetailByNotCheck`,
    method: 'post',
    data
  })
}

// 0421一键赋值计算不含税单价
export function oneKey(data) {
  return request({
    url: `/scm-purchase/vendorOffer/oneKey`,
    method: 'post',
    data
  })
}
// 0425明细对账确认前合计数据(不勾选数据对账)
export function confirmCheckBillDetailBySum(params) {
  return request({
    url: `/scm-purchase/checkBill/confirmCheckBillDetailBySum`,
    method: 'get',
    params
  })
}

// 610销量预测
export function demandForecast(params) {
  return request({
    url: `/scm-purchase/statistics/demandForecast`,
    method: 'get',
    params
  })
}

// 下单预测
export function placeOrderForecast(params) {
  return request({
    url: `/scm-purchase/statistics/placeOrderForecast`,
    method: 'get',
    params
  })
}

export function demandUnclearedForecast(params) {
  return request({
    url: `/scm-purchase/statistics/demandUnclearedForecast`,
    method: 'get',
    params
  })
}

// 批量打印鞋标 - 打印
export function batchPrintShoesCode(data) {
  return request({
    url: `/scm-purchase/order/batchPrintShoesCode`,
    method: 'post',
    data
  })
}

// 采购订单
export function listPlatFormSiteNumber(data) {
  return request({
    url: `scm-purchase/order/listPlatFormSiteNumber`,
    method: 'post',
    data
  })
}
// 缺量完结PO单
export function shortageCompletion(data) {
  return request({
    url: `/scm-purchase/order/shortageReceipt`,
    method: 'post',
    data
  })
}
// 供应商报价更新记录
export function vendorOfferPo(params) {
  return request({
    url: `/scm-purchase/vendorOfferPo/page`,
    method: 'get',
    params
  })
}

// 报价更新ID获取详情
export function vendorOfferPoById(id) {
  return request({
    url: `scm-purchase/vendorOfferPo/${id}`,
    method: 'get'
  })
}

export function shoesBatchNoImportView(data) {
  return request({
    url: `/scm-purchase/order/shoesBatchNoImportView`,
    method: 'post',
    data
  })
}
export function shoesBatchNoImport(data) {
  return request({
    url: `/scm-purchase/order/shoesBatchNoImport`,
    method: 'post',
    data
  })
}

// 采购计划导入
export function purchasePlanImportView(data) {
  return request({
    url: `/scm-purchase/plan/purchasePlanImportView`,
    method: 'post',
    data
  })
}
// Eden 合同管理
export function getContractList(params) {
  return request({
    url: `/scm-purchase/purchaseFrameworkContract/page`,
    method: 'get',
    params
  })
}
// 作废合同
export function cancelContract(data) {
  return request({
    url: `scm-purchase/purchaseFrameworkContract/cancel`,
    method: 'put',
    data
  })
}

export function purchasePlanImport(data) {
  return request({
    url: `/scm-purchase/plan/purchasePlanImport`,
    method: 'post',
    data,
    timeout: 720000
  })
}
// 上传合同
export function uploadContract(data) {
  return request({
    url: `/scm-purchase/purchaseFrameworkContract/upload`,
    method: 'post',
    data
  })
}

// 一键刷新
export function refreshFnskuAndUpc(params) {
  return request({
    url: `/scm-purchase/plan/refreshFnskuAndUpc`,
    method: 'get',
    params
  })
}
// 删除合同
export function deleteContract(id) {
  return request({
    url: `scm-purchase/purchaseFrameworkContract/${id}`,
    method: 'delete'
  })
}

// 下载
export function downloadByEdon(params) {
  return request({
    url: `/scm-purchase/basic/download`,
    method: 'get',
    params,
    responseType: 'arraybuffer'
  })
}

// 获取采购订单合同信息
export function getPOContractList(params) {
  return request({
    url: `/scm-purchase/purchaseOrderContract/getPOContractList`,
    method: 'get',
    params
  })
}

// 生成采购合同校验
export function createOrderContractCheck(data) {
  return request({
    url: `/scm-purchase/order/createOrderContractCheck`,
    method: 'post',
    data
  })
}
// 内盒打印鞋标 - 打印
export function boxPrintShoesCode(data) {
  return request({
    url: `/scm-purchase/order/boxPrintShoesCode`,
    method: 'post',
    data
  })
}
// 生成采购订单合同
export function createOrderContract(templateType, data) {
  return request({
    url: `/scm-purchase/order/createOrderContract/${templateType}`,
    method: 'post',
    data
  })
}

// 采购订单合同分页查询
export function getPOList(params) {
  return request({
    url: `/scm-purchase/order/getPOList`,
    method: 'get',
    params
  })
}
// 采购合同分页
export function purchaseOrderContractPage(data) {
  return request({
    url: `/scm-purchase/purchaseOrderContract/page`,
    method: 'post',
    data
  })
}

// 作废采购订单合同
export function cancelPOContract(data) {
  return request({
    url: `scm-purchase/purchaseOrderContract/cancel`,
    method: 'put',
    data
  })
}

// 获取下载路径列表
export function getDownloadUrlList(data) {
  return request({
    url: `/scm-purchase/basic/getDownloadUrlList`,
    method: 'post',
    data
  })
}
// 更新下载次数
export function updateDownloadNum(data) {
  return request({
    url: `scm-purchase/purchaseOrderContract/updateDownloadNum`,
    method: 'PUT',
    data
  })
}

// 采购订单合同详情
export function getPOContractLogList(params) {
  return request({
    url: `scm-purchase/purchaseOrderContract/getPoContractList`,
    method: 'get',
    params
  })
}

export function uploadPdf(data) {
  return request({
    url: `/scm-purchase/purchaseOrderContract/uploadPdf`,
    method: 'post',
    data
  }
  )
}
// 下拉查询供应商
export function vendorOfferSelect(params) {
  return request({
    url: `/scm-purchase/vendorOffer/select/pending`,
    method: 'get',
    params
  })
}

export function modifyPackageCode(data) {
  return request({
    url: `/scm-purchase/order/modifyPackageCode`,
    method: 'post',
    data
  })
}

export function getPurchasePlanReportByPage(params) {
  return request({
    url: `/scm-purchase/PurchasePlanReport/getPurchasePlanReportByPage`,
    method: 'get',
    params
  })
}

export function getPurchasePlanDetailReportByPage(params) {
  return request({
    url: `/scm-purchase/PurchasePlanReport/getPurchasePlanDetailReportByPage`,
    method: 'get',
    params
  })
}

export function getPurchaseOrderReportByStyle(params) {
  return request({
    url: `/scm-purchase/PurchaseOrderReport/getPurchaseOrderReportByStyle`,
    method: 'get',
    params
  })
}
export function download(params) {
  return request({
    url: `/scm-purchase/purchaseOrderContract/download`,
    method: 'get',
    params
  })
}
// 获取飞书采购员信息
export function getPurchaserList() {
  return request({
    url: `/scm-purchase/basic/purchasePerson`,
    method: 'get'
  })
}

export function getPurchaseOrderReportBySku(params) {
  return request({
    url: `/scm-purchase/PurchaseOrderReport/getPurchaseOrderReportBySku`,
    method: 'get',
    params
  })
}
export function downloadFrameFile(params) {
  return request({
    url: `/scm-purchase/purchaseFrameworkContract/download`,
    method: 'get',
    params
  })
}

export function approval(data) {
  return request({
    url: `/scm-purchase/purchaseOrderContract/sign/approval`,
    method: 'post',
    data
  })
}

export function approvalCheckRefuse(data) {
  return request({
    url: `/scm-purchase/purchaseOrderContract/sign/approvalCheckRefuse`,
    method: 'post',
    data
  })
}

export function replenishApproval(data) {
  return request({
    url: `/scm-purchase/replenishContract/sign/approval`,
    method: 'post',
    data
  })
}

export function frameApproval(data) {
  return request({
    url: `/scm-purchase/purchaseFrameworkContract/sign/approval`,
    method: 'post',
    data
  })
}

export function replenishCheckRefuse(data) {
  return request({
    url: `/scm-purchase/replenishContract/sign/approvalCheckRefuse`,
    method: 'post',
    data
  })
}

export function replenishContractPage(params) {
  return request({
    url: `/scm-purchase/replenishContract/page`,
    method: 'get',
    params
  })
}

export function replenishContractDetail(agreementCode) {
  return request({
    url: `/scm-purchase/replenishContract/link_purchase/${agreementCode}`,
    method: 'get'
  })
}

export function contractSave(data) {
  return request({
    url: `/scm-purchase/replenishContract/save`,
    method: 'post',
    data
  })
}

export function contractSaveNoFile(data) {
  return request({
    url: `/scm-purchase/replenishContract/save_purchase`,
    method: 'post',
    data
  })
}
export function orderCodeList(params) {
  return request({
    url: `/scm-purchase/replenishContract/get_purchase_contract`,
    method: 'get',
    params
  })
}

export function frameCodeList(params) {
  return request({
    url: `/scm-purchase/replenishContract/get_framwork_contract`,
    method: 'get',
    params
  })
}

export function delContract(id) {
  return request({
    url: `/scm-purchase/replenishContract/del_contract/${id}`,
    method: 'get'
  })
}

export function cancelReplenishContract(id, data) {
  return request({
    url: `/scm-purchase/replenishContract/cancel_contract/${id}`,
    method: 'post',
    data
  })
}

export function contractUpload(data) {
  return request({
    url: `/scm-purchase/replenishContract/upload`,
    method: 'post',
    data
  })
}

export function replenishContractDownload(params) {
  return request({
    url: `/scm-purchase/replenishContract/download`,
    method: 'get',
    params
  })
}

export function frameworkApproval(data) {
  return request({
    url: `/scm-purchase/replenishContract/sign/frameworkApproval`,
    method: 'post',
    data
  })
}

export function changeDeliveryInfoView(data) {
  return request({
    url: `/scm-purchase/order/changeDeliveryInfoView`,
    method: 'post',
    data
  })
}

export function changeDeliveryInfo(data) {
  return request({
    url: `/scm-purchase/order/changeDeliveryInfo`,
    method: 'post',
    data
  })
}

// 分页查询待确认的供应商反馈交期
export function getFeedbackPage(params) {
  return request({
    url: `/scm-purchase/order/getFeedbackPage`,
    method: 'get',
    params
  })
}
// 导出供应商反馈交期
export function exportFeedback(params) {
  return request({
    url: `/scm-purchase/order/exportFeedback`,
    method: 'get',
    responseType: 'arraybuffer',
    params
  })
}
// 驳回供应商交期反馈
export function rejectFeedback(params) {
  return request({
    url: `/scm-purchase/order/rejectFeedback`,
    method: 'get',
    params
  })
}

// 供应商反馈交期确认
export function passFeedback(data) {
  return request({
    url: `/scm-purchase/order/passFeedback`,
    method: 'post',
    data
  })
}
// 供应商交期反馈确认导入预览
export function feedbackImportView(data) {
  return request({
    url: `/scm-purchase/order/feedbackImportView`,
    method: 'post',
    data
  })
}
// 供应商交期反馈导入确认
export function feedbackImport(data) {
  return request({
    url: `/scm-purchase/order/feedbackImport`,
    method: 'post',
    data
  })
}
export function templateList(params) {
  return request({
    url: `/scm-purchase/approval/templateList`,
    method: 'get',
    params
  })
}

export function editTemplate(id) {
  return request({
    url: `/scm-purchase/approval/editTemplate/${id}`,
    method: 'get'
  })
}

export function saveTemplate(data) {
  return request({
    url: `/scm-purchase/approval/saveTemplate`,
    method: 'post',
    data
  })
}
export function templateView(data) {
  return request({
    url: `/scm-purchase/approval/templateView`,
    method: 'post',
    data
  })
}

export function returnGoodsOrderExport(params) {
  return request({
    url: `/scm-purchase/rg/returnGoodsOrderExport`,
    method: 'get',
    params,
    responseType: 'arraybuffer' })
}

export function getFileDownloadUrlByFileIds(data) {
  return request({
    url: `/scm-purchase/ossDownload/getFileDownloadUrlByFileIds`,
    method: 'post',
    data
  })
}

export function uploadByPreUrlApi(params) {
  return request({
    url: `/scm-purchase/ossUpload/uploadByPreUrl`,
    method: 'get',
    params
  })
}

export function listLtSubstandardLog(purchaseOrderCode) {
  return request({
    url: `scm-purchase/order/listLtSubstandardLog/${purchaseOrderCode}`,
    method: 'get'
  })
}

export function preDeliveryImport(data) {
  return request({
    url: `/scm-purchase/order/preDeliveryImport`,
    method: 'post',
    data
  })
}

export function appLink() {
  return request({
    url: `/scm-purchase/helpdesk/appLink`,
    method: 'get'
  })
}

export function getFeedbackCount() {
  return request({
    url: `/scm-purchase/order/getFeedbackCount`,
    method: 'get'
  })
}

// 定位
export function position() {
  return request({
    url: `/scm-purchase/basic/position`,
    method: 'get'
  })
}
// 站点
export function getSiteListByRelation() {
  return request({
    url: `/scm-purchase/basic/siteListByRelation`,
    method: 'get'
  })
}

export function getFrameworkContractExpiryDateTip(data) {
  return request({
    url: `/scm-purchase/replenishContract/getFrameworkContractExpiryDateTip`,
    method: 'post',
    data
  })
}

// 获取平台站点下拉框
export function getplatformSite(params) {
  return request({
    url: `/scm-purchase/basic/platformSite`,
    method: 'get',
    params
  })
}
// 获取带权限的平台站点下拉框
export function getlimitedplatformSite(params) {
  return request({
    url: `/scm-purchase/basic/getUserPlatformSiteTreeNodeList`,
    method: 'get',
    params
  })
}

// 获取平台站点店铺下拉框
export function getplatformSiteShop(params) {
  return request({
    url: `/scm-purchase/basic/platformSiteShop`,
    method: 'get',
    params
  })
}

// 带权限用户品牌信息查询
export function LimitedqueryBrandList() {
  return request({
    url: `/scm-purchase/basic/getUserEnableBrandList`,
    method: 'get'
  })
}

// 根据SKU 获取对应的style、color、size信息
export function getSkuInfoList(params) {
  return request({
    // url: `/scm-purchase/basic/getSkuInfo/{sku}`,
    url: `/scm-purchase/basic/getSkuInfo/${params}`,
    method: 'get',
    params
  })
}
// rtr分页查询、导出、新增预处理、新增、修改、批量修改、删除、导入、导入预览
export function rtrssdApi(data, pager) {
  return request({
    url: `/scm-purchase/sd/listByPage/?current=${pager.current}&size=${pager.size}&pages=${pager.pages}&total=${pager.total}`,
    method: 'post',
    data
  })
}
export function rtrssdExport(data) {
  return request({
    url: `/scm-purchase/sd/export`,
    method: 'post',
    data,
    responseType: 'arraybuffer'
  })
}
export function preInsertSsr(data) {
  return request({
    url: `/scm-purchase/sd/preInsert`,
    method: 'post',
    data
  })
}
export function InsertSsr(data) {
  return request({
    url: `/scm-purchase/sd/insert`,
    method: 'post',
    data
  })
}
export function ModifySsr(data) {
  return request({
    url: `/scm-purchase/sd/update`,
    method: 'post',
    data
  })
}
export function BatchModifySsr(data) {
  return request({
    url: `/scm-purchase/sd/batchUpdate`,
    method: 'post',
    data
  })
}
export function DeleteSsr(data) {
  return request({
    url: `/scm-purchase/sd/delete`,
    method: 'post',
    data
  })
}

// 分配供应商生产预览
export function allocationVendorProdView(data) {
  return request({
    url: `scm-purchase/order/allocationVendorProdView`,
    method: 'post',
    data
  })
}
export function ImportViewSsr(data) {
  return request({
    url: `/scm-purchase/sd/preImport`,
    method: 'post',
    data,
    timeout: 9999999

  })
}
export function ImportSsr(data) {
  return request({
    url: `/scm-purchase/sd/importCommit`,
    method: 'post',
    data,
    timeout: 9999999
  })
}

// 分配供应商校验
export function toVendorProdView(data) {
  return request({
    url: `scm-purchase/order/allocationVendorContract`,
    method: 'post',
    data
  })
}

// MPS天数查询
export function getMpsDayApi(data, pager) {
  return request({
    url: `/scm-purchase/mpsDays/page/?current=${pager.current}&size=${pager.size}&pages=${pager.pages}&total=${pager.total}`,
    method: 'post',
    data
  })
}

// mps——导出、新增、修改、删除、导入预览、导入
export function mpsExport(data) {
  return request({
    url: `/scm-purchase/mpsDays/export`,
    method: 'post',
    data,
    responseType: 'arraybuffer'
  })
}
export function InsertMps(data) {
  return request({
    url: `/scm-purchase/mpsDays/insert`,
    method: 'post',
    data
  })
}
export function ModifyMps(data) {
  return request({
    url: `/scm-purchase/mpsDays/update`,
    method: 'post',
    data
  })
}
export function DeleteMps(data) {
  return request({
    url: `/scm-purchase/mpsDays/delete`,
    method: 'post',
    data
  })
}
export function ImportMpsView(data) {
  return request({
    url: `/scm-purchase/mpsDays/importView`,
    method: 'post',
    data
  })
}
export function ImportMps(data) {
  return request({
    url: `/scm-purchase/mpsDays/importSubmit`,
    method: 'post',
    data
  })
}

// 补货时效相关配置-分页查询，导出，新增，修改，删除，导入预览，导入提交
export function getReplenishApi(data, pager) {
  return request({
    url: `/scm-purchase/replenishAging/page/?current=${pager.current}&size=${pager.size}&pages=${pager.pages}&total=${pager.total}`,
    method: 'post',
    data
  })
}
export function replenishExport(data) {
  return request({
    url: `/scm-purchase/replenishAging/export`,
    method: 'post',
    data,
    responseType: 'arraybuffer'
  })
}
export function InsertReplenish(data) {
  return request({
    url: `/scm-purchase/replenishAging/insert`,
    method: 'post',
    data
  })
}
export function ModifyReplenish(data) {
  return request({
    url: `/scm-purchase/replenishAging/update`,
    method: 'post',
    data
  })
}
export function DeleteReplenish(data) {
  return request({
    url: `/scm-purchase/replenishAging/delete`,
    method: 'post',
    data
  })
}
// 分配供应商生产导入预览
export function importVendorProdView(data, poArray) {
  return request({
    url: `scm-purchase/order/allocationVendorProdImportView?purchaseOrderCodeList=${poArray}`,
    method: 'post',
    data
  })
}

export function ImportReplenishView(data) {
  return request({
    url: `/scm-purchase/replenishAging/importView`,
    method: 'post',
    data
  })
}
// 平台站点级联
export function allPlatSiteTreeNodeList(data) {
  return request({
    url: `/scm-purchase/basic/platSiteShopBasic/allPlatSiteTreeNodeList`,
    method: 'post',
    data
  })
}

// 供应商报价-保存补充附件
export function saveAttachmentApi(data) {
  return request({
    url: `/scm-purchase/VendorOfferAttachment/saveAttachment`,
    method: 'post',
    data
  })
}

export function ImportReplenish(data) {
  return request({
    url: `/scm-purchase/replenishAging/importSubmit`,
    method: 'post',
    data
  })
}
// 供应商报价-删除附件
export function deleteAttachmentApi(data) {
  return request({
    url: `/scm-purchase/VendorOfferAttachment/deleteAttachment`,
    method: 'delete',
    data
  })
}

// 新模板下载
export function findVendorDownloadUrl(templateType) {
  return request({
    url: `/scm-purchase/template/getTemplateUrlByTemplateType/${templateType}`,
    method: 'get'
  })
}

// 翻单时效配置——分页查询，导出，新增，修改，删除，导入预览，导入提交
export function getFlippingApi(params) {
  return request({
    url: `/scm-purchase/reorderAging/page`,
    method: 'get',
    params
  })
}
export function FlippingExport(params) {
  return request({
    url: `/scm-purchase/reorderAging/export`,
    method: 'get',
    params,
    responseType: 'arraybuffer'
  })
}
export function InsertFlipping(data) {
  return request({
    url: `/scm-purchase/reorderAging/insert`,
    method: 'post',
    data
  })
}
export function ModifyFlipping(data) {
  return request({
    url: `/scm-purchase/reorderAging/update`,
    method: 'post',
    data
  })
}
export function DeleteFlipping(data) {
  return request({
    url: `/scm-purchase/reorderAging/delete`,
    method: 'post',
    data
  })
}
export function ImportFlippingView(data) {
  return request({
    url: `/scm-purchase/reorderAging/importView`,
    method: 'post',
    data
  })
}
export function ImportFlipping(data) {
  return request({
    url: `/scm-purchase/reorderAging/importSubmit`,
    method: 'post',
    data
  })
}

// 备货渠道版本下拉
export function StockChannelList(params) {
  return request({
    url: `/scm-purchase/basic/getStockChannelList`,
    method: 'get',
    params
  })
}
export function StockChannelApi(params) {
  return request({
    url: `/scm-purchase/basic/getStockChannelList`,
    method: 'get',
    params
  })
}
// 带平台站点店铺的备货渠道下拉
export function StockChannelAllList(params) {
  return request({
    url: `/scm-purchase/basic/stockChannelList`,
    method: 'get',
    params
  })
}
// 销售预测版本下拉
export function dsbVersionList(params) {
  return request({
    url: `/scm-purchase/basic/dsbVersion`,
    method: 'get',
    params
  })
}

// 销售预测版本下拉
export function getDsbVersion(params) {
  return request({
    url: `/scm-purchase/basic/dsbVersion`,
    method: 'get',
    params
  })
}
// 销售预测同步
export function getSalesForecastApi(data, pager) {
  return request({
    url: `/scm-purchase/sd/listSalePlan/?current=${pager.current}&size=${pager.size}&pages=${pager.pages}&total=${pager.total}`,
    method: 'post',
    data
  })
}
export function planSkuSalesExport(data) {
  return request({
    url: `/scm-purchase/export/exportPlanSkuSales`,
    method: 'post',
    data
  })
}
// 批量调整FCST导入预览
export function ImportSalePlanView(data) {
  return request({
    url: `/scm-purchase/sd/preSalePlanImport`,
    method: 'post',
    data
  })
}
export function ImportSalePlan(data) {
  return request({
    url: `/scm-purchase/sd/salePlanImportCommit`,
    method: 'post',
    data
  })
}
// 销售预测手动同步
export function synByHand(data) {
  return request({
    url: `/scm-purchase/sd/synByHand`,
    method: 'post',
    data
  })
}
export function listRecord(data, pager) {
  return request({
    url: `/scm-purchase/sd/listRecord/?current=${pager.current}&size=${pager.size}&pages=${pager.pages}&total=${pager.total}`,
    method: 'post',
    data
  })
}
// 调配记录
export function provisionLog(data, pager) {
  return request({
    url: `/scm-purchase/provisionLog/page/?current=${pager.current}&size=${pager.size}&pages=${pager.pages}&total=${pager.total}`,
    method: 'post',
    data
  })
}
export function provisionLogExport(data) {
  return request({
    url: `/scm-purchase/provisionLog/export`,
    method: 'post',
    data
  })
}

// 调拨单管理
export function transferOrderApi(data, pager) {
  return request({
    url: `/scm-purchase/transferOrder/page/?current=${pager.current}&size=${pager.size}&pages=${pager.pages}&total=${pager.total}`,
    method: 'post',
    data
  })
}
// 新增
export function insertSave(data) {
  return request({
    url: `/scm-purchase/transferOrder/insertSave`,
    method: 'post',
    data
  })
}
export function insertSubmit(data) {
  return request({
    url: `/scm-purchase/transferOrder/insertSubmit`,
    method: 'post',
    data
  })
}

// 批量新增
export function batchInsertView(data) {
  return request({
    url: `/scm-purchase/transferOrder/batchInsertView`,
    method: 'post',
    data
  })
}
export function batchInsertPreValidate(data) {
  return request({
    url: `/scm-purchase/transferOrder/batchInsertPreValidate`,
    method: 'post',
    data
  })
}
export function batchInsertValidate(data) {
  return request({
    url: `/scm-purchase/transferOrder/batchInsertValidate`,
    method: 'post',
    data
  })
}
// 一键赋值调拨类型
export function calcTransferType(data) {
  return request({
    url: `/scm-purchase/transferOrder/calcTransferType`,
    method: 'post',
    data
  })
}
// 调拨单导入
export function importTransView(data) {
  return request({
    url: `/scm-purchase/transferOrder/importView`,
    method: 'post',
    data
  })
}
export function createTransferOrderView(data) {
  return request({
    url: `/scm-purchase/transferOrder/createTransferOrderView`,
    method: 'post',
    data
  })
}
export function createTransferOrderSubmit(data) {
  return request({
    url: `/scm-purchase/transferOrder/createTransferOrderSubmit`,
    method: 'post',
    data,
    timeout: 720000
  })
}
// 调拨单导出、导出明细
export function exportTrans(data) {
  return request({
    url: `/scm-purchase/transferOrder/export`,
    method: 'post',
    data
  })
}
export function exportDetailTrans(data) {
  return request({
    url: `/scm-purchase/transferOrder/exportDetail`,
    method: 'post',
    data
  })
}
// 调拨单缺量调拨与撤销调拨
export function shortageTransfer(data) {
  return request({
    url: `/scm-purchase/transferOrder/shortageTransfer`,
    method: 'post',
    data
  })
}
export function cancelTransfer(data) {
  return request({
    url: `/scm-purchase/transferOrder/cancelTransfer`,
    method: 'post',
    data
  })
}
// 调拨单转仓库作业
export function transferWarehousePreTip(data) {
  return request({
    url: `/scm-purchase/transferOrder/transferWarehousePreTip`,
    method: 'post',
    data
  })
}
export function transferWarehouseView(data) {
  return request({
    url: `/scm-purchase/transferOrder/transferWarehouseView`,
    method: 'post',
    data
  })
}
export function transferWarehouse(data) {
  return request({
    url: `/scm-purchase/transferOrder/transferWarehouse`,
    method: 'post',
    data
  })
}
// 调拨单查看与修改
export function TransferInfo(params) {
  return request({
    url: `/scm-purchase/transferOrder/info/${params}`,
    method: 'get',
    params
  })
}
export function getmodifyTransferView(params) {
  return request({
    url: `/scm-purchase/transferOrder/modifyView/${params}`,
    method: 'get',
    params
  })
}
export function modifyTransferSave(data) {
  return request({
    url: `/scm-purchase/transferOrder/modifySave`,
    method: 'post',
    data
  })
}
export function modifyTransferSubmit(data) {
  return request({
    url: `/scm-purchase/transferOrder/modifySubmit`,
    method: 'post',
    data
  })
}
// 补货记录查询与导出
export function replenishRecordApi(data, pager) {
  return request({
    url: `/scm-purchase/replenish/replenishRecordList/?current=${pager.current}&size=${pager.size}&pages=${pager.pages}&total=${pager.total}`,
    method: 'post',
    data
  })
}
export function exportReplenishRecord(data) {
  return request({
    url: `/scm-purchase/export/exportReplenishRecord`,
    method: 'post',
    data
  })
}
// 翻单管理
export function listByPage(data, pager) {
  return request({
    url: `/scm-purchase/produce/listByPage/?current=${pager.current}&size=${pager.size}&pages=${pager.pages}&total=${pager.total}`,
    method: 'post',
    data
  })
}
export function preImportProduce(data) {
  return request({
    url: `/scm-purchase/produce/preImport`,
    // ?skuNames=${skuNames}&stockChannelName=${stockChannelName}
    method: 'post',
    data
  })
}
export function Toproduce(data) {
  return request({
    url: `/scm-purchase/produce/produce`,
    method: 'post',
    data
  })
}
export function groupPurchasePlan(data) {
  return request({
    url: `/scm-purchase/produce/groupPurchasePlan`,
    method: 'post',
    data
  })
}
export function generatePurchasePlan(data) {
  return request({
    url: `/scm-purchase/produce/generatePurchasePlan`,
    method: 'post',
    data
  })
}
// 获取翻单周期
export function getProduceCycle(params) {
  return request({
    url: `/scm-purchase/produce/getProduceCycle`,
    method: 'get',
    params
  })
}
// 翻单管理导出
export function exportProduce(data) {
  return request({
    url: `/scm-purchase/export/exportProduce`,
    method: 'post',
    data
  })
}
// 获取style\color的仓库code
export function getStyleColorWarehouseCodes(params) {
  return request({
    url: `/scm-purchase/basic/getStyleColorWarehouseCodes`,
    method: 'get',
    params
  })
}

// 获取用户权限Style
export function getLimitedStyle(data) {
  return request({
    url: `/scm-purchase/basic/userStyleList`,
    method: 'post',
    data
  })
}

// dsb04/05
export function purchasePlanToOrderPreviewFnSkuV2(data) {
  return request({
    url: `/scm-purchase/plan/purchasePlanToOrderPreviewFnSkuV2`,
    method: 'post',
    data
  })
}
// 打印内里标
export function boxInsideLabel(data) {
  return request({
    url: `/scm-purchase/order/boxInsideLabel`,
    method: 'post',
    data
  })
}

// 导出全部标签
export function boxPrintAll(data) {
  return request({
    url: `/scm-purchase/order/boxPrintAll`,
    method: 'post',
    data
  })
}

export function exportRecycle(data) {
  return request({
    url: `/scm-purchase/export/exportRecycle`,
    method: 'post',
    data
  })
}

// dsb订单标签导入预览
export function orderTagImportView(data) {
  return request({
    url: `scm-purchase/order/orderTagImportView`,
    method: 'post',
    data
  })
}

// 采购订单取消获取邮件
export function cancelOrderEmailInfo(purchaseOrderCode) {
  return request({
    url: `/scm-purchase/order/cancelOrder/emailDetail?purchaseOrderCode=${purchaseOrderCode}`,
    method: 'post'
  })
}

// 发送邮件
export function cancelOrderSendEmail(data) {
  return request({
    url: `/scm-purchase/order/cancelOrder/sendEmail`,
    method: 'post',
    data
  })
}

export function orderTagImportSubmit(data) {
  return request({
    url: `scm-purchase/order/orderTagImportSubmit`,
    method: 'post',
    data
  })
}

// 供应商报价-模板下载
export function downloadVendorQuote(data) {
  return request({
    url: `/scm-purchase/vendorOffer/downloadTemplate `,
    method: 'post',
    data,
    responseType: 'arraybuffer'
  })
}

// moq 全量color
export function selectColorList(params) {
  return request({
    url: `/scm-purchase/basic/selectColorList`,
    method: 'get',
    params
  })
}

// moq 有效color
export function selectInuseColorList(params) {
  return request({
    url: `/scm-purchase/basic/selectInuseColorList`,
    method: 'get',
    params
  })
}

// 获取color所有关联的styleName
export function selectStyleByColorIds(data) {
  return request({
    url: `/scm-purchase/basic/selectStyleByColorIds`,
    method: 'post',
    data
  })
}

export function produceNext(data) {
  return request({
    url: `/scm-purchase/produce/produceNext`,
    method: 'post',
    data
  })
}

// 重新发起审批
export function createApproveApi(id) {
  return request({
    url: `/scm-purchase/rg/createApprove?id=${id}`,
    method: 'post'
  })
}
