<template>
  <section class="app-main">
    <transition name="fade-transform" mode="out-in">
      <keep-alive :include="cachedViews">
        <el-card>
          <router-view :key="key" />
        </el-card>
      </keep-alive>
    </transition>

    <Footer />
  </section>
</template>

<script>

import Footer from './Footer'
export default {
  name: 'AppMain',
  components: {
    Footer
  },
  computed: {
    cachedViews() {
      return this.$store.state.tagsView.cachedViews
    },
    key() {
      return this.$route.path
    }
  }

}
</script>

<style lang="scss" scoped>
.app-main {
  /* 50= navbar  50  */
  min-height: calc(100vh - 50px);
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 20px;
  background-color: #f0f2f5;
}

.app-container{
    background-color: #fff;
  border-radius: 5px;
}
 body > .el-container {
    margin-bottom: 40px;
  }
.fixed-header+.app-main {
  padding-top: 50px;
}

.hasTagsView {
  .app-main {
    /* 84 = navbar + tags-view = 50 + 34 */
    min-height: calc(100vh - 84px);
  }

  .fixed-header+.app-main {
    padding-top: 84px;
  }
}
</style>

<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 15px;
  }
}
.row-center{
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
