
const state = {
  shipmentTableDatasSelect: '',
  addOverboughtDataObject: {},
  replenishmentObject: {},
  flipOrderData: [],
  OverboughtSkuTableData: [],
  moqComfirmTableData: [],
  overboughtVendorData: [],
  getSplitDetails: [],
  splitOrderData: [],
  warehouseNameSet: [],
  currentSkuChannelOverboughtList: []
}

const mutations = {
  INIT_TABLE_SELECT: (state, data) => {
    state.shipmentTableDatasSelect = data
  },

  SET_REPLENISHMENT_DATA: (state, data) => {
    state.replenishmentObject = data
  },
  SET_FLIPORDER_DATA: (state, data) => {
    state.flipOrderData = data
  },
  SET_OVERBOUGHT_OBJECT: (state, data) => {
    state.addOverboughtDataObject = data
  },
  SET_OVERBOUGHT_SKU_TABLEDATA: (state, data) => {
    state.OverboughtSkuTableData = data
  },
  SET_MOQ_COMFIRM_TABLEDATA: (state, data) => {
    state.moqComfirmTableData = data
  },

  SET_OVERBOUGHT_VENDOR_DATA: (state, data) => {
    state.overboughtVendorData = data
  },
  SET_OVERBOUGHT_SPLIT_SKU_DATA: (state, data) => {
    state.getSplitDetails = data
  },
  SET_OVERBOUGHT_SPLIT_ORDER_DATA: (state, data) => {
    state.splitOrderData = data
  },
  SET_WAREHOUSE_NAME: (state, data) => {
    state.warehouseNameSet = data
  },
  SET_CURRENT_OVERBOUGHT_SKU_TABLEDATA: (state, data) => {
    state.currentSkuChannelOverboughtList = data
  }
}

const actions = {
  initTableSelect({ commit }, data) {
    commit('INIT_TABLE_SELECT', data)
  },

  setReplenishmentData({ commit }, data) {
    commit('SET_REPLENISHMENT_DATA', data)
  },
  setflipOrderData({ commit }, data) {
    commit('SET_FLIPORDER_DATA', data)
  },
  setOverboughtObject({ commit }, data) {
    commit('SET_OVERBOUGHT_OBJECT', data)
  },
  setWarehouseNameSet({ commit }, data) {
    commit('SET_WAREHOUSE_NAME', data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
