
const state = {
  replenishment: {},
  replenishmentActiveName: '1',
  purchasePlanForm: {},
  purchaseOrderForm: {},
  transferOrderForm: {},
  flipActiveName: 'first',
  flip: {},
  redundantForm: {}
}

const mutations = {
  SAVE_REPLENISHMENT: (state, { data, currentTab }) => {
    state.replenishment = data
    state.replenishmentActiveName = currentTab
  },
  SAVE_PURCHASEPLAN: (state, data) => {
    state.purchasePlanForm = data
  },
  SAVE_PURCHASEORDER: (state, data) => {
    state.purchaseOrderForm = data
  },
  SAVE_TRANSFERORDER: (state, data) => {
    state.transferOrderForm = data
  },
  SAVE_FLIPRORDER: (state, { data, currentTab }) => {
    state.flip = data
    state.flipActiveName = currentTab
  },
  SAVE_REDUNDANTFORM: (state, data) => {
    state.redundantForm = data
  }

}

const actions = {
  saveReplenishment({ commit }, { data, currentTab }) {
    commit('SAVE_REPLENISHMENT', { data, currentTab })
  },
  savePurchasePlan({ commit }, data) {
    commit('SAVE_PURCHASEPLAN', data)
  },
  savePurchaseOrder({ commit }, data) {
    commit('SAVE_PURCHASEORDER', data)
  },
  saveTransferOrder({ commit }, data) {
    commit('SAVE_TRANSFERORDER', data)
  },
  saveFlipOrder({ commit }, { data, currentTab }) {
    commit('SAVE_FLIPRORDER', { data, currentTab })
  },
  saveRedundantForm({ commit }, data) {
    commit('SAVE_REDUNDANTFORM', data)
  }

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
