<template>
  <div>
    <svg-icon class-name="clear-cache" icon-class="clear-cache" @click.stop="handleClearCache" />
  </div>
</template>

<script>

import Cookies from 'js-cookie'
export default {
  name: 'ClearCache',
  methods: {
    handleClearCache() {
      this.$confirm(this.$t('title.clearCache'), this.$t('page.Prompt'), {
        confirmButtonText: this.$t('title.confirm'),
        cancelButtonText: this.$t('title.cancel'),
        type: 'warning'
      }).then(() => {
        localStorage.clear()
        document.cookie.split(';').forEach((c) => {
          const name = c.split('=')[0].trim()
          Cookies.remove(name)
        })
        location.reload()
      })
    }
  }
}
</script>

<style scoped>
.clear-cache {
    cursor: pointer;
    font-size: 18px;
    vertical-align: middle;
  }
</style>
