import Vue from 'vue'

import Cookies from 'js-cookie'

import 'normalize.css/normalize.css' // a modern alternative to CSS resets

import Element from 'element-ui'
import './styles/element-variables.scss'

import '@/styles/index.scss' // global css

import App from './App'
import store from './store'
import router from './router'

import i18n from './lang' // internationalization
import './icons' // icon
import './permission' // permission control
import './utils/error-log' // error log

import * as filters from './filters' // global filters
import { getProtocolAdjustedUrl } from '@/utils/auth'

import permission from '@/directive/permission'
import { mockXHR } from '../mock'
import Print from '@/plugs/print'
import VeLine from 'v-charts/lib/line.common'
import VePie from '@/lib/v-charts/pie.common'
import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/index.css'
import VueMatomo from 'vue-matomo'

Vue.use(permission)
Vue.component(VeLine.name, VeLine)
Vue.component(VePie.name, VePie)
Vue.use(Element, {
  size: Cookies.get('size') || 'medium', // set element-ui default size
  i18n: (key, value) => i18n.t(key, value)
})
Vue.use(Print)
Vue.use(VXETable, {
  size: 'small',
  tooltipConfig: {
    zIndex: 3000
  },
  table: {
    resizable: true,
    autoResize: true,
    border: true,
    showOverflow: true,
    scrollY: {
      gt: 20
    },
    scrollX: {
      gt: 20
    }
  }
})
Vue.use(VueMatomo, {
  host: getProtocolAdjustedUrl(process.env.VUE_APP_MATOMO),
  siteId: process.env.VUE_APP_MATOMO_SITEID,
  router: router,
  requireConsent: false,
  enableLinkTracking: true,
  trackInitialView: false,
  trackerFileName: 'matomo',
  debug: false
})
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

if (process.env.NODE_ENV === 'development') {
  mockXHR()
}

Vue.config.productionTip = false

// 防止el-button重复点击
Vue.directive('preventReClick', {
  inserted(el, binding) {
    el.addEventListener('click', () => {
      if (!el.disabled) {
        el.disabled = true
        el.style.cursur = 'not-allowed'
        setTimeout(() => {
          el.disabled = false
          el.style.cursor = 'pointer'
        }, binding.value || 2000)
      }
    })
  }
})
new Vue({
  el: '#app',
  router,
  store,
  i18n,
  render: h => h(App)
})
