const getters = {
  sidebar: state => state.app.sidebar,
  language: state => state.app.language,
  size: state => state.app.size,
  device: state => state.app.device,
  // 全局仓库
  warehouse: state => state.user.warehouse,
  warehouseList: state => state.user.warehouseList,

  visitedViews: state => state.tagsView.visitedViews,
  cachedViews: state => state.tagsView.cachedViews,
  token: state => state.user.token,
  jwtToken: state => state.user.jwtToken,
  refreshToken: state => state.user.refreshToken,
  avatar: state => state.user.avatar,
  authorities: state => state.user.authorities,
  name: state => state.user.name,
  tenantId: state => state.user.tenantId,
  userId: state => state.user.userId,
  introduction: state => state.user.introduction,
  roles: state => state.user.roles,
  menu: state => state.user.menu,
  authorization: state => state.user.authorization,
  permission_routes: state => state.permission.routes,
  errorLogs: state => state.errorLog.logs,

  replenishmentObject: state => state.pages.replenishmentObject,
  flipOrderData: state => state.pages.flipOrderData,
  addOverboughtDataObject: state => state.pages.addOverboughtDataObject,

  // dialog
  visibleFirst: state => state.dialog.visibleFirst,
  visibleSecond: state => state.dialog.visibleSecond,
  visibleThird: state => state.dialog.visibleThird,
  visibleFourth: state => state.dialog.visibleFourth,
  visibleFifth: state => state.dialog.visibleFifth,
  visibleSixth: state => state.dialog.visibleSixth,
  // permissions
  permissions: state => state.permission.permissions,
  // page
  shipmentTableDatasSelect: state => state.pages.shipmentTableDatasSelect,
  // 保存form表单信息
  replenishment: state => state.saveForm['replenishment'],
  replenishmentActiveName: state => state.saveForm.replenishmentActiveName,
  purchasePlanForm: state => state.saveForm.purchasePlanForm,
  purchaseOrderForm: state => state.saveForm.purchaseOrderForm,
  transferOrderForm: state => state.saveForm.transferOrderForm,
  flip: state => state.saveForm.flip,
  flipActiveName: state => state.saveForm.flipActiveName,
  redundantForm: state => state.saveForm.redundantForm,
  // 生成采购订单dsb06
  OverboughtSkuTableData: state => state.pages.OverboughtSkuTableData,
  moqComfirmTableData: state => state.pages.moqComfirmTableData,
  // MOQ
  overboughtVendorData: state => state.pages.overboughtVendorData,
  getSplitDetails: state => state.pages.getSplitDetails,
  splitOrderData: state => state.pages.splitOrderData,
  warehouseNameSetStore: state => state.pages.warehouseNameSet,
  currentSkuChannelOverboughtList: state => state.pages.currentSkuChannelOverboughtList
}
export default getters
