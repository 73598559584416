export default {
  route: {
    AllAddedQuote: 'All added quote',
    ViewQuote: 'View quote',
    ReportManage: 'Report manage',
    PurchaseLedger: 'Purchase ledger',
    Supplier: 'Supplier',
    ThirdParty: 'Supplier Management Center',
    StyleInfomgmt: 'Style info ',
    TurnoverMGMT: 'Turnover management',
    Added: ' Add/Modify',
    PurchasePrice: 'Purchase price maintenance',
    PurchasePriceAdded: 'Add/modify price plan',
    PurchasPusiness: 'Purchas business',
    PurchasPlan: 'Purchas plan',
    AddedPlanOrder: 'New purchase plan',
    AllAddedOrder: 'Add in bulk',
    GenerateOrder: 'Generate purchase order',
    ViewoOrder: 'Viewo order',
    ViewOrderDetail: 'View details',
    PurchaseOrder: 'Purchase order',
    PurchDetail: 'Purchase order details',
    SupplierQuote: 'Supplier quote',
    AddedQuote: 'Added quote',
    ApprovalFlow: 'Approval flow',
    ApprovalFlowMGMT: 'Approval management',
    ApprovalFlowMGMT1: 'Operation team leader approval',
    ApprovalFlowMGMT2: 'Operations Director Approval',
    ApprovalFlowMGMT3: 'Plan approval',
    ApprovalFlowMGMT4: 'Financial approval',
    ApprovalFlowMGMT5: 'Procurement approval',
    ApprovalFlowAdded: 'Approval flow details',
    QualityInspection: 'Quality inspection',
    Qualitymgmt: 'Quality inspection management',
    QualitymgmtAdded: 'Quality inspection submission',
    ProductProofing: 'New product proofing',
    Last: 'Last management',
    Proofing: 'Product proofing',
    Prodprogress: 'Prod progress survey',
    dashboard: 'Homepage',
    Profile: 'Profile',
    BasicData: 'BasicData',
    Blurb: 'Blurb',
    Box: 'Box',
    Brand: 'Brand',
    Case: 'Case',
    OneCategory: 'One category',
    TowCategory: 'Two category',
    ThreeCategory: 'Three category',
    SkuMaterialComponent: 'Sku material component',
    MaterialInformation: 'Material information',
    SkuOtherInformation: 'Sku other information',
    SkuPlatform: 'Sku platform',
    Size: 'Size',
    Sku: 'Sku',
    FinancialManage: 'Financial Manage',
    PurchanseReconciliation: 'Purchanse Reconciliation',
    NoReconciliation: 'No Reconciliation',
    PayManage: 'Pay Manage',
    PurchaseReturn: 'Purchase Return',
    OwnerMannagement: 'Owner Mannagement',
    DelayOrder: 'DelayOrder',
    FailToPredict: 'FailToPredict',
    PayToPredict: 'PayToPredict',
    GoodsToPredict: 'GoodsToPredict',
    AgingReport: 'Aging Report',
    DepositCheck: 'Deposit Check',
    ExceptionHandling: 'ExceptionHandling',
    AuditManagement: 'Audit Management',
    Audit: 'Audit',
    AuditInspectionReport: 'Audit Inspection Report',
    AssetRules: 'Asset Rules',
    QuotationUpdateRecord: 'Quotation Update Record',
    PayableReport: 'PayableReport',
    OpenExtension: 'OpenExtension',
    SupplierSettlement: 'Supplier Settlement',
    StatementManagement: 'Statement Management',
    StatementManagementDetail: 'Detail',
    PurchaseSettlement: 'Purchase Settlement Management',
    FinancialPayment: 'Financial Payment management',
    packingBox: 'packing Box Manage',
    Contract: 'Contract',
    OrderContract: 'Order Contract',
    FrameContract: 'Frame Contract',
    ChangeVendorName: 'Change The Vendor Name',
    PurchasePlan: 'Purchase Plan',
    PurchaseOrderReport: 'Purchase Order',
    ConfigurationManage: 'Configuration Manage',
    ApprovalNotice: 'Approval Notice',
    ResponsiblePerson: 'Responsible Person',
    SupplementaryContract: 'Supplementary Agreement to Contract',
    ResponsibleAutomatic: 'Responsible person Automatic configuration',
    PoPriceAdjustment: 'Po Price Adjustment',
    PoPriceAdjustmentAdd: 'Po Price Adjustment Add',
    PoPriceAdjustmentBatchAdd: 'Po Price Adjustment BatchAdd'
  },
  navbar: {
    dashboard: 'Homepage',
    github: 'Github',
    logOut: 'Log out',
    profile: 'Profile',
    theme: 'Theme',
    size: 'Global size',
    ShoeLabelFile: 'Shoe label file'
  },
  login: {
    title: 'Login Form for SCM',
    logIn: 'Login',
    username: 'Username',
    password: 'Password',
    verificationCode: 'Verification Code',
    LoginFailed: 'Login failed',
    Relogin: 'Re-login',
    HaveBeenLoggedOut: 'You have been logged out, you can cancel to stay on this page, or log in again'
  },
  footer: {
    help: 'help',
    privacy: 'privacy',
    clause: 'clause'
  },
  page: {
    capitalLetterAndNumber: 'Please enter two letters or numbers',
    plzChooseDateTime: 'Please choose date and time',
    IncorrectFormat: 'Incorrect format',
    IncorrectFormatVendorSimple: 'Incorrect format,Please enter two letters or numbers',
    positioning: 'Positioning',
    targetNumberOfDays: 'Target number of days',
    address: 'Address',
    requestSuccess: 'Success',
    addPhotos: 'Add photos',
    /** 公用 */
    requestTimeOut: 'The request has timed out. Please try again. ',
    homepage: 'homepage',
    Details: 'Details',
    search: 'Search',
    reset: 'Reset',
    expand: 'Expand',
    hide: 'Hide',
    create: 'Create',
    export: 'Export',
    others: 'others',
    cancel: 'cancel',
    total: 'Total',
    submit: 'Submit',
    /** 个人中心 */
    BasicInformation: 'BasicInformation',
    required: 'Required',
    Newpurchaseplan: 'New purchase plan',
    Style: 'Style',
    Color: 'Color',
    Size: 'Size',
    // SKU其他信息
    ModifySKUOtherInfo: 'Modify sku other info',
    firstLevelSuppliers: 'First level suppliers',
    secondLevelSuppliers: 'Second level suppliers',
    /** 个人中心 */
    InformationManagement: 'Information management',
    username: 'Username',
    Email: 'Email',
    Avatar: 'Avatar',
    PasswordManagement: 'Password management',
    Password: 'Password',
    Newpassword: 'New password',
    ConfirmPassword: 'Confirm password',
    ResetPassword: 'Reset password',
    Character: 'Character',
    Staff: 'Staff',
    status: 'Status',
    image: 'Image',
    supplier: 'Supplier',
    SKUImages: 'SKU imgages',
    EditImages: 'Edit images',
    AddImages: 'Add images',
    skuMainPic: 'Master drawing',
    auxiliary: 'Auxiliary chart ',
    model: 'Model figure',
    SureToDeleteDetail: 'Are you sure you want to delete the details?',
    Prompt: 'Prompt',
    UsernameAlreadyExists: 'User name already exists, please re-enter',
    Password6: 'Password must be at least 6 characters',
    UsernameIsInvalid: 'Username is invalid',
    PlzInputPassword: 'Please input password',
    StartWith1Letters: 'Start with 1 letters and end with 1 digit. For example: a1',
    StartWith2Letters: 'Start with 2 letters and end with 1 digit. For example: aa1',
    Enter2Letters: 'Please enter 2 letters (A-Z) characters, such as AA',
    categoryOneRequired: 'Category one is required',
    categoryTwoRequired: 'Category two is required',
    ColorRequired: 'Color is required',
    ColorKeyRequired: 'Color key is required',
    StatusRequired: 'Status is required',
    categoryRequired: 'Category is required',
    SizeRequired: 'Size is required',
    SizeKeyRequired: 'Size key is required',
    digits3: '3 digits, cannot start with 0',
    digits34: '3 or 4 digits, cannot start with 0',
    digits16: 'Please enter a 1-6 digit integer greater than 0',
    digits16port4: 'Please enter more than 0, 1-6 digits and retain 4 decimal places',
    IncompleteBasicInformation: 'Incomplete basic information cannot be saved, please complete and try again',
    addMaterial: 'First add material components to the style',
    GeneratedFromOther: 'Sku is generated from other data',
    typeBy: 'Type',
    LabelReplenishPrint: 'Label Replenish Print',
    contactNumber: 'Contact Number',
    phoneNumber: 'Phone Number',
    ConfirmImport: 'Confirm import',
    vendorSimpleRequired: 'vendorSimple Required',
    deliveryImport: 'delivery Import',
    UploadTypeTips: 'only xls or xlsx',
    UploadSizeTips: 'cannot more than 10M',
    SureDelete: 'Sure Delete'
  },
  title: {
    // 文件导出
    ExportTaskName: 'Export Task Name',
    ExportCompleTime: 'Export Completion Time',
    AbnormalInformation: 'Abnormal Information',

    Approvalnumber: 'Approval number',
    Associatednumber: 'Associated number',
    Approvaltype: 'Approval type',
    Purchaseplan: 'Purchase plan',
    Operationapproval: 'Operation approval',

    onePrintDetail: 'Please select at least one print data',
    printType: 'Print type',
    Permission: 'Permission',
    back: 'Back',
    Save: 'Save',
    Edit: 'Edit',
    Replace: 'Replace',
    BasicInformation: 'Basic information',
    submit: 'Submit',
    cancel: 'Cancel',
    confirm: 'Confirm',
    ExportDetail: 'Export detail',
    // 一级类别表头字段翻译
    OneCategory: 'One category',
    OneCategoryKey: 'One category key',
    // 二级类别翻译
    TwoCategory: 'Two category',
    TwoCategoryKey: 'Two category key',
    // 三级类别翻译
    ThreeCategory: 'Three category',
    ThreeCategoryKey: 'Three category key',
    // 品牌表头翻译
    BrandName: 'Brand name',
    BrandKey: 'Brand key',
    // 款式翻译
    Style: 'Style',
    Brand: 'Brand',
    Years: 'Years',
    Season: 'Season',
    Spring: 'Spring',
    Summer: 'Summer',
    Autumn: 'Autumn',
    Winter: 'Winter',
    All: 'All',
    AllSeason: 'Four seasons',
    SectionNumber: 'Section number',
    // SKU材料组件管理
    Components: 'Components',
    OtherInformation: 'SKU other information',
    // 平台SKU信息
    Color: 'Color',
    Size: 'Size',
    ThisSelection: 'This selection',
    Strip: 'Strip SKU',
    SKUTitle: 'Modify SKU colors',
    SKUStatus: 'Modify SKU status',
    PrintField: 'Print field',
    PrintPaper: 'Print paper',
    ReplaceSKU: 'Replace SKU',
    AssignUPC: 'Assign UPC',
    Category: 'Category',
    ReplaceTime: 'Replace time',
    Yes: 'Yes',
    No: 'No',
    StyleInformation: 'Style information',
    SKUInfo: 'SKU information',
    SurfaceMaterial: 'Surface material',
    InMaterial: 'In material',
    BottomMaterial: 'Bottom material',
    CushionMaterial: 'Cushion material',
    OtherInfo: 'Other info',
    SkuPicture: 'SKU picture',
    BatchEditColor: 'Batch edit color',
    BatchEditStatus: 'Batch edit status',
    print: 'Print',
    Site: 'Site',
    Platform: 'Platform',
    // 盒子翻译
    boxLabel: 'Box',
    BoxName: 'Box name',
    BoxHeight: 'Box height',
    BoxWidth: 'Box width',
    BoxLength: 'Box length',
    BoxWeight: 'Box weight',
    boxVolume: 'Box volume',
    // 箱规翻译
    CartonName: 'Carton name',
    CartonNumber: 'Carton number',
    CartonHeight: 'Carton height',
    CartonWidth: 'Carton width',
    cartonLength: 'carton length',
    CartonWeight: 'Carton weight',
    CartonVolume: 'Carton volume',
    boxSize: 'Box size',
    shoeTree: 'Shoe tree',
    // SKU管理
    SimplifySKU: 'Simplify plat_sku',
    filterplatform: 'The plat_sku generated after the check will delete spaces, _ replaced with-, before-more than 30 parts will be automatically deleted',
    leastsync: 'Please select at least one SKU sync',
    Synchronize: 'Synchronize SKU to site',
    synchronizeFnskuUpc: 'Synchronize FNSKU/UPC',
    synchronousSource: 'Please select the synchronous source platform/site',
    pleaseSynchronous: 'Please select synchronous encoding',
    EditSku: 'Edit SKU',
    Choose: 'Choose',
    StripNumber: 'Strip number',
    Unit: 'Box/Double',
    BoxUnit: 'Box(length*width*weight)',
    chooseAsku: 'at least choose a sku to modify',
    modifySameStyle: 'please choose the same style to modify',
    // 材料信息翻译
    MaterialInformation: 'Material information',
    MaterialsName: 'Materials name',
    materialsKey: 'materials key',
    // 供应商翻译
    vendorName: 'Supplier name',
    vendorSimple: 'vendor Simple',
    vendorFull: 'vendor Full',
    vendorType: 'Supplier grade',
    province: 'province',
    city: 'city',
    Country: 'Country',
    districtsAndCounties: 'Districts and counties',
    vendorCode: 'Supplier number',
    registeredAddr: 'Supplier Registration Information',
    detailedAddr: 'Supplier detailed address',
    salesReturnAddr: 'Return address',
    payType: 'payType',
    minimumNum: 'Minimum purchases',
    cargoSrc: 'Domestic source',
    taxRates: 'Billing tax rate',
    brcfilelist: 'Three Certificates in One:',
    lfoafilelist: 'Opening permit:',
    pafilelist: 'Purchase agreement:',
    depositBank: 'depositBank',
    depositName: 'depositName',
    bankAccount: 'bankAccount',
    taxIdentificationCode: 'Taxpayer Identification Number',
    Established: 'Established',
    fax: 'fax',
    email: 'email',
    Wechat: 'Wechat',
    qq: 'QQ',
    buyer: 'buyer',
    accountPeriod: 'Account period',
    changeReturn: 'Are you sure to transfer the WMS return delivery order?',
    enterpriseSize: 'Enterprise size',
    generalTaxpayer: 'General taxpayer',
    smallScale: 'Small scale',
    addressInformation: 'address Information',
    contractingBody: 'contracting Body',
    bankName: 'bank Name',
    beneficiaryBankName: 'beneficiary Bank Name',
    bankNumber: 'bank Number',
    quotationCurrency: 'quotation Currency'

  }
}
