const state = {
  visibleFirst: false,
  visibleSecond: false,
  visibleThird: false,
  visibleFourth: false,
  visibleFifth: false,
  visibleSixth: false
}
// First Second Third Fourth Fifth Sixth

const mutations = {
  CHANGE_VISIBLE_FIRST: (state) => {
    state.visibleFirst = !state.visibleFirst
  },
  CHANGE_VISIBLE_SECOND: (state) => {
    state.visibleSecond = !state.visibleSecond
  },
  CHANGE_VISIBLE_THIRD: (state) => {
    state.visibleThird = !state.visibleThird
  },
  CHANGE_VISIBLE_FOURTH: (state) => {
    state.visibleFourth = !state.visibleFourth
  },
  CHANGE_VISIBLE_FIFTH: (state) => {
    state.visibleFifth = !state.visibleFifth
  },
  CHANGE_VISIBLE_SIXTH: (state) => {
    state.visibleSixth = !state.visibleSixth
  },
  INIT_VISIBLE: (state) => {
    state.visibleFirst = false
    state.visibleSecond = false
    state.visibleThird = false
    state.visibleFourth = false
    state.visibleFifth = false
    state.visibleSixth = false
  }
}

const actions = {
  changevisibleFirst({ commit }) {
    commit('CHANGE_VISIBLE_FIRST')
  },
  changevisibleSecond({ commit }) {
    commit('CHANGE_VISIBLE_SECOND')
  },
  changevisibleThird({ commit }) {
    commit('CHANGE_VISIBLE_THIRD')
  },
  changevisibleFourth({ commit }) {
    commit('CHANGE_VISIBLE_FOURTH')
  },
  changevisibleFifth({ commit }) {
    commit('CHANGE_VISIBLE_FIFTH')
  },
  changevisibleSixth({ commit }) {
    commit('CHANGE_VISIBLE_SIXTH')
  },
  initVisible({ commit }) {
    commit('INIT_VISIBLE')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
