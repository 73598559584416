<template>
  <footer>
    <div class="footer-select">
      <router-link class="navigation" to="/">{{ $t('footer.help') }}</router-link>
      <router-link class="navigation" to="/">{{ $t('footer.privacy') }}</router-link>
      <router-link class="navigation" to="/">{{ $t('footer.clause') }}</router-link>
    </div>
    <div class="company-name">wangoon©弯弓信息科技有限公司</div>
    <HoverBtn @hoverBtnClick="hoverBtnClick" />

  </footer>

</template>

<script>
import { appLink } from '@/api/scm-api'
import HoverBtn from '@/components/HoverBtn'
export default {
  name: 'Footer',
  components: { HoverBtn },
  data() {
    return {
    }
  },
  methods: {
    async hoverBtnClick() {
      const { datas } = await appLink()
      if (datas) {
        // const iframe = document.createElement('iframe')
        // iframe.style.display = 'none'
        // iframe.style.height = 0
        // iframe.src = datas
        // document.body.appendChild(iframe)
        // setTimeout(() => {
        //   iframe.remove()
        // }, 100)

        datas && window.open(datas, '_blank')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
footer {
  margin-top: 40px;
  color:#858687;
  font-size: 14px;

}
.footer-select {
  display: flex;
  justify-content: center;
  align-items: center;
  .navigation {
    padding: 10px;
  }
}
.company-name {
  text-align: center;
}
.pda {
  text-align: center;
}
@keyframes radar-beam {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
</style>
