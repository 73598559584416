import Vue from 'vue'
import Router from 'vue-router'
import Iframe from '@/layout/Iframe'

Vue.use(Router)

/* Layout */
import Layout from '@/layout'
import NullRouterView from '@/layout/null-router-view'

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [
  // {
  //   path: '/test',
  //   component: Layout,
  //   name: 'GrafanaLayout',
  //   children: [{
  //     path: '',
  //     name: 'Grafana',
  //     component: Iframe,
  //     meta: {
  //       title: 'iframe-test',
  //       iframeUrl: 'https://bi.aliyun.com/token3rd/dashboard/view/pc.htm?pageId=5cbce1bd-25bf-4dec-883f-e0b1e9c5bf1c&embedDisplayParam=%7B%22showTitle%22%3Atrue%7D&accessTicket=167293a3-c117-47c8-9533-3c32b2102055',
  //       icon: 'grafana'
  //     }
  //   }
  //   ]
  // },
  {
    path: '/iframe/financial-manage/supplier-settlement/statement-management/details/index',
    component: () =>
      import('@/views/financial-manage/supplier-settlement/statement-management/details/index'),
    hidden: true
  },
  // 测试fin文件导出
  {
    path: '/iframe/import-file',
    component: () =>
      import('@/views/import-file/index'),
    hidden: true
  },
  // wms调拨单
  {
    path: '/iframe/replenishment-allocation-management/transfer-order-management',
    component: () => import('@/views/plan-deliver/transfer-order-management/index'),
    hidden: true,
    name: 'IframeTransferOrderManagement'
  },
  {
    path: '/iframe/replenishment-allocation-management/transfer-order-management/details',
    component: () => import('@/views/plan-deliver/transfer-order-management/details/index'),
    name: 'IframeDetail',
    hidden: true
  },
  {
    path: '/iframe/replenishment-allocation-management/transfer-order-management/modify',
    component: () => import('@/views/plan-deliver/transfer-order-management/modify/index'),
    name: 'IframeTransferOrderModify',
    hidden: true
  },
  {
    path: '/iframe/replenishment-allocation-management/transfer-order-management/added',
    component: () => import('@/views/plan-deliver/transfer-order-management/added/index'),
    name: 'IframeTransferOrderAdded',
    hidden: true
  },
  {
    path: '/iframe/replenishment-allocation-management/transfer-order-management/added/all-transfer-order-added',
    component: () => import('@/views/plan-deliver/transfer-order-management/added/all-transfer-order-added/index'),
    hidden: true,
    name: 'IframeAllTransferOrderAdded'
  },
  {
    path: '/iframe/replenishment-allocation-management/transfer-order-management/to-warehouse',
    component: () => import('@/views/plan-deliver/transfer-order-management/to-warehouse/index'),
    hidden: true,
    name: 'IframeToWarehouse'
  },
  {
    path: '/vendor/reconcile',
    component: () => import('@/views/vendor-reconcile/index'),
    hidden: true
  },
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path*',
        component: () => import('@/views/redirect/index')
      }
    ]
  },
  {
    path: '/profile',
    component: Layout,
    redirect: '/profile',
    hidden: true,
    children: [
      {
        path: '/',
        component: () => import('@/views/profile'),
        name: 'Profile',
        meta: {
          title: 'Profile'
        }
      }
    ]
  },
  {
    path: '/inner-dashboard',
    component: () => import('@/views/dashboard/index'),
    hidden: true
  },
  {
    path: '/auth-redirect',
    component: () => import('@/views/login/auth-redirect'),
    hidden: true
  },
  {
    path: '/404',
    component: () => import('@/views/error-page/404'),
    hidden: true
  },
  {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    children: [
      {
        path: 'dashboard',
        component: () => import('@/views/dashboard/index'),
        name: 'Dashboard',
        meta: {
          title: 'dashboard',
          icon: 'dashboard'
        }
      }
    ]
  }
]

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export const asyncRoutes = []
const createRouter = () =>
  new Router({
    mode: 'history',
    scrollBehavior: () => ({
      y: 0
    }),
    routes: constantRoutes
  })

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
